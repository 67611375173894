<template>
  <div
    class="z-30 fixed w-full grid place-items-center bg-white border-red-700 border-b-4"
  >
    <img
      class="py-2 w-12 place-self-center"
      alt="logo"
      src="../assets/logo.png"
    />
    <nav id="nav" v-click-outside="onClickOutside">
      <ul>
        <li>
          <div
            v-on:click="open"
            class="header-right-icon text-gray-700 text-sm place-content-center flex flex-row"
          >
            <div class="mr-2 text- font-bold flex-1 flex-col">
              <span class="flex justify-end"> ログインID：{{ loginId }} </span>
              <span class="flex justify-end"> {{ name }} さん </span>
            </div>
            <font-awesome-icon icon="user-circle" size="3x" class="flex" />
            <ul class="dropdown" :class="{ isOpen }">
              <li>
                <router-link
                  :to="{
                    name: 'password-change',
                    params: {
                      isReturn: 1,
                    },
                  }"
                >
                  <span class="mr-2">
                    <font-awesome-icon icon="unlock-alt" size="lg" />
                  </span>
                  パスワード変更
                </router-link>
              </li>
              <li>
                <a v-on:click="logout">
                  <span class="mr-2">
                    <font-awesome-icon icon="sign-out-alt" size="lg" />
                  </span>
                  ログアウト
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>

  <h1
    class="h-16 p-3 text-center items-center bg-white text-red-700 border-red-700 border-b-4 text-2xl font-bold"
  >
    OPS
  </h1>
</template>

<script>
  import vClickOutside from "vue3-click-outside";
  import { Cookie } from "../utils/cookie";
  import { isNavigationFailure, NavigationFailureType } from "vue-router";

  export default {
    name: "Header",
    props: {
      msg: String,
    },
    data() {
      return {
        isOpen: false,
        loginId: this.$store.state.loginId,
        name: this.$store.state.displayName,
      };
    },
    methods: {
      open() {
        this.isOpen = !this.isOpen;
      },
      onClickOutside() {
        this.isOpen = false;
      },
      logout() {
        // TODO: ログアウトAPI必要か？
        // ログイン画面へ遷移
        this.$router
          .push({
            name: "login",
          })
          .then((failure) => {
            // 遷移がキャンセルされなかった場合
            if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
              // storeに保持している情報を初期化
              localStorage.removeItem("ops-front-app");
              // localStrageに保持している情報を初期化
              localStorage.removeItem("iToken");
              localStorage.removeItem("aToken");
              // cookieからtokenを削除
              Cookie.deleteToken();
            }
          });
      },
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-right-icon {
    position: fixed;
    right: 36px;
    top: 7px;
    cursor: pointer;
  }

  #nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  #nav > ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  #nav > ul > li {
    margin: 0 20px 0 0;
  }

  #nav > ul > li > span {
    display: block;
    height: auto;
    text-decoration: none;
  }

  #nav > ul > li > span > svg {
    @apply mb-2;
  }

  .dropdown {
    position: absolute;
    top: 55px;
    right: -36px;
    display: none;
    padding: 0;
    list-style-type: none;
    background-color: #c53030;
  }

  .dropdown li {
    width: 200px;
    border-bottom: 1px solid #fff;
  }

  .dropdown li a {
    display: block;
    padding: 15px 25px;
    color: #fff;
    text-decoration: none;
  }

  .dropdown li a:hover {
    @apply bg-red-900;
  }

  .isOpen {
    @apply block;
  }
</style>
