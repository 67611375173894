import { searchReleasestatus, deletePushNotification, searchTemplates, getDivisions, getPushDestinations, getTemplate, } from "../api";
import Title from "../components/TheTitle.vue";
import Form from "../components/ReleaseStatusForm.vue";
import Table from "../components/ReleaseStatusTable.vue";
import { UserAuth } from "../const/userAuthority";
import { Authorize } from "../utils/authorize";
export default {
    name: "ReleaseStatus",
    data() {
        return {
            searchedTemplateTitle: [],
            templateTitleList: [],
            searchedReleaseStatuses: [],
            searchedDivisions: [],
            isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
            errMsgs: [],
            templateIdCondition: null,
            releaseDateStartCondition: null,
            releaseDateEndCondition: null,
            infoStatusCondition: null,
            divisionCodeCondition: "2",
        };
    },
    components: {
        Title,
        Form,
        Table,
    },
    created: async function () {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority === UserAuth.SYSTEM_ADMIN.code) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 画面表示時、テンプレートのためタイトル一覧取得
            const templatesTitleRspBody = await searchTemplates(null, null, "0");
            this.searchedTemplateTitle = templatesTitleRspBody.detail;
            this.searchedTemplateTitle.forEach((value) => {
                // 初期値(グランフォト)の値のみを出力
                if (value.divisionCode === "2") {
                    this.templateTitleList.push(value);
                }
            });
            // 事業部一覧全件取得
            const divisionsRspBody = await getDivisions();
            this.searchedDivisions = divisionsRspBody.detail;
        }
        else {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
    },
    methods: {
        async searchReleasestatus(templateId, releaseDateStart, releaseDateEnd, infoStatus, divisionCode) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 配信状況を検索
            const rspBody = await searchReleasestatus(templateId, releaseDateStart, releaseDateEnd, infoStatus, divisionCode);
            if (rspBody.result.messages.length) {
                this.searchedReleaseStatuses = [];
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 成功した場合、取得したレコードを渡す
                this.searchedReleaseStatuses = rspBody.detail;
                // 検索条件を保持
                this.templateIdCondition = templateId;
                this.releaseDateStartCondition = releaseDateStart;
                this.releaseDateEndCondition = releaseDateEnd;
                this.infoStatusCondition = infoStatus;
                this.divisionCodeCondition = divisionCode;
            }
        },
        async templateFilter(divisionCode) {
            this.templateTitleList = [];
            this.searchedTemplateTitle.forEach((value) => {
                if (value.divisionCode === divisionCode) {
                    this.templateTitleList.push(value);
                }
            });
        },
        async releaseCancel(id) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await deletePushNotification(id);
            // 再度フォームの検索条件を取得
            if (rspBody.result.messages.length) {
                this.searchedReleaseStatuses = [];
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.searchedReleaseStatuses = rspBody.detail;
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 再度フォームの検索条件でレコードを取得
                const releaseStatusRspBody = await searchReleasestatus(this.templateIdCondition, this.releaseDateStartCondition, this.releaseDateEndCondition, this.infoStatusCondition, this.divisionCodeCondition);
                this.searchedReleaseStatuses = releaseStatusRspBody.detail;
            }
        },
        async getPushDestinations(id) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await getPushDestinations(id);
            return rspBody.detail;
        },
        async getBody(templateId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await getTemplate(templateId);
            return rspBody.detail.body;
        },
    },
};
