import { searchCustomerChangeRequests, updateCustomerChangeRequest, getUpdateReqDetails, } from "../api";
import Title from "../components/TheTitle.vue";
import Form from "../components/UpdateRequestForm.vue";
import Table from "../components/UpdateRequestTable.vue";
import { UserAuth } from "../const/userAuthority";
import { Authorize } from "../utils/authorize";
export default {
    name: "updateRequest",
    components: {
        Title,
        Form,
        Table,
    },
    data() {
        return {
            searchedRequests: [],
            storeIdCondition: [],
            customerNoCondition: [],
            statusCdCondition: [],
            isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
            errMsgs: [],
        };
    },
    created: async function () {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority !== UserAuth.SYSTEM_ADMIN.code &&
            authority !== UserAuth.STORE_ADMIN.code) {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
        if (Date.now() / 1000 > this.$store.state.tokenExp) {
            if (!(await Authorize.isAuth())) {
                // 認証失敗：ログイン画面へ遷移
                this.$router.push({
                    name: "login",
                    query: { redirect: this.$route.path },
                });
                return false;
            }
        }
    },
    methods: {
        async search(storeId, customerNo, statusCd) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await searchCustomerChangeRequests(storeId, customerNo, statusCd);
            if (rspBody.result.messages.length) {
                this.searchedRequests = [];
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 成功した場合、取得したレコードを渡す
                this.searchedRequests = rspBody.detail;
                // 検索条件を保持させる
                this.storeIdCondition = storeId;
                this.customerNoCondition = customerNo;
                this.statusCdCondition = statusCd;
            }
        },
        async completeRequest(requestId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await updateCustomerChangeRequest(requestId);
            if (rspBody.result.messages.length) {
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            // 現在の検索条件で検索し、再表示する
            this.search(this.storeIdCondition, this.customerNoCondition, this.statusCdCondition);
        },
        async getUpdateReqDetails(id) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await getUpdateReqDetails(id);
            return rspBody.detail;
        },
    },
};
