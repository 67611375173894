import { login } from "../api";
import ErrMsgCard from "../components/ErrorMssageCard.vue";
import { Message } from "../const/message";
import { Validation } from "../utils/validation";
import { CryptoUtils } from "../crypto";
import { Cookie } from "../utils/cookie";
import { v4 as uuidv4 } from "uuid";
export default {
    name: "Login",
    components: {
        ErrMsgCard,
    },
    data() {
        return {
            loginId: { key: "ログインID", value: "" },
            password: { key: "パスワード", value: "" },
            isPassVisible: false,
            errMsgs: [],
            isShowConfirmModal: false,
        };
    },
    async created() {
        if (this.$route.query.errMsg) {
            const queryErrMsg = this.$route.query.errMsg;
            if (Array.isArray(queryErrMsg)) {
                // エラーメッセージが配列の場合、ループ
                queryErrMsg.forEach((msgCd) => {
                    // コードを日本語のメッセージに変換してerrMsgsセット
                    const msg = Message.LOGIN_ERROR[msgCd].message;
                    this.errMsgs.push(msg);
                });
            }
            else {
                // エラーメッセージが配列ではない場合
                // コードを日本語のメッセージに変換してerrMsgsセット
                const msg = Message.LOGIN_ERROR[queryErrMsg].message;
                this.errMsgs.push(msg);
            }
        }
        // ログアウト時のlocalStrageとVuexの削除待ち（0.5秒）
        await new Promise((resolve) => setTimeout(resolve, 500));
        // ログイン済みの場合はトップページにリダイレクト
        if (localStorage.getItem("iToken")) {
            this.$router.push({ name: "user" });
        }
    },
    methods: {
        submit() {
            // パスワードをマスキング
            this.isPassVisible = false;
            // バリデーション定義作成
            let validationArray = [];
            // バリデーションチェック実施
            this.errMsgs = validationArray.concat(Validation.requiredInput(this.loginId), Validation.requiredInput(this.password));
            if (this.errMsgs.length) {
                // エラーメッセージがある場合は処理を中断
                return false;
            }
            // パスワードの暗号化
            let encryptoPass = CryptoUtils.encryptoAes(this.password.value);
            // UUIDを取得
            var clientId = Cookie.getClientId();
            if (!clientId) {
                clientId = uuidv4();
            }
            // cookieにクライアントIDをセット
            Cookie.setClientId(clientId);
            // ログインAPI呼び出し
            this.callApi(encryptoPass, false);
        },
        passVisibleToggle() {
            this.isPassVisible = !this.isPassVisible;
        },
        callApi(encryptoPass, isDeviceChanged) {
            login(async (rspBody) => {
                if (rspBody.result.code === 9) {
                    // 処理結果が失敗の場合
                    if (rspBody.result.messages.length) {
                        // エラーメッセージを表示
                        this.errMsgs = rspBody.result.messages;
                    }
                }
                else {
                    // 処理結果が成功の場合
                    let user = rspBody.detail;
                    if (user.isDeviceChangeConfirmed) {
                        // 使用したことが無い端末でログインしているため、確認ダイアログを表示させる
                        this.openModal(encryptoPass);
                    }
                    else {
                        // 初回ログイン、または端末変更のため、二段階認証画面へ遷移する
                        if (!user.isLogined) {
                            this.$router.push({
                                name: "two-Factor-Auth",
                                query: {
                                    crmUserId: this.loginId.value,
                                    authority: user.userType,
                                    session: user.session,
                                },
                            });
                            return;
                        }
                        else {
                            // 以下、通常ログインの場合の処理
                            // IDトークンとアクセストークンを保持
                            localStorage.setItem("iToken", user.idToken);
                            localStorage.setItem("aToken", user.accessToken);
                            // cookieにIDトークンをセット
                            Cookie.setToken(user.idToken);
                            // リフレッシュトークンとユーザ情報を保持
                            this.$store.commit("setRToken", user.refreshToken);
                            this.$store.commit("setTokenExp", user.tokenExp);
                            this.$store.commit("setStoreCd", user.storeId);
                            this.$store.commit("setAuthority", user.userType);
                            this.$store.commit("setDisplayName", user.displayName);
                            this.$store.commit("setLoginId", user.crmUserId);
                            if (user.isNotChangedPassword === "1") {
                                // パスワード未変更の場合はパスワード変更画面へ遷移
                                this.$router.push({
                                    name: "password-change",
                                    params: {
                                        isReturn: 0,
                                    },
                                });
                            }
                            else {
                                // 上記以外の場合はユーザ一覧ページへ遷移
                                this.$router.push({ name: "user" });
                            }
                        }
                    }
                }
            }, this.loginId.value, encryptoPass, isDeviceChanged);
        },
        /** 確認モーダルウィンドウを開く */
        openModal(encryptoPass) {
            const msg = [
                "登録されていない端末でログインしようとしています。",
                "この端末を普段使用する端末として登録しますか？",
            ];
            this.$store.commit("setMessages", msg);
            this.$store.commit("setOkBtnMsg", "はい");
            this.$store.commit("setCancelBtnMsg", "いいえ");
            this.$store.commit("setOkAction", () => {
                // ダイアログではいを選択した場合、isDeviceChangedをtrueとし、もう一度APIを呼出す
                this.callApi(encryptoPass, true);
                return;
            });
            this.$store.commit("setCancelAction", () => {
                return;
            });
            this.$store.commit("setIsModalOpen", true);
        },
        resetPassword() {
            this.$router.push({
                name: "password-reset",
            });
        },
    },
    computed: {
        passInputType() {
            return this.isPassVisible ? "text" : "password";
        },
    },
};
