<template>
  <div id="app" class="bg-blue-50 flex flex-col min-h-screen">
    <Header
      v-if="
        $route.path !== '/' &&
        $route.path !== '/login/redirect' &&
        $route.path !== '/two-Factor-Auth' &&
        $route.path !== '/password-change' &&
        $route.path !== '/password-reset' &&
        $route.path !== '/maintenance'
      "
    />
    <SideMenu
      v-if="
        $route.path !== '/' &&
        $route.path !== '/login/redirect' &&
        $route.path !== '/two-Factor-Auth' &&
        $route.path !== '/password-change' &&
        $route.path !== '/password-reset' &&
        $route.path !== '/maintenance'
      "
    />
    <div class="mt-14 antialiased text-gray-900 px-6">
      <router-view />
    </div>
    <Modal />
    <Loading :loading="$store.state.loading" />
  </div>
</template>

<script>
  import SideMenu from "./components/TheSideMenu.vue";
  import Header from "./components/TheHeader.vue";
  import Modal from "./components/TheModal.vue";
  import Loading from "./components/TheLoading.vue";

  export default {
    name: "Top",
    components: {
      SideMenu,
      Header,
      Modal,
      Loading,
    },
    created() {
      this.$store.commit("setIsModalOpen", false);
    },
    methods: {
      setTitle(to) {
        if (to.meta.title) {
          document.title = to.meta.title;
        }
      },
    },
    watch: {
      $route(to) {
        this.setTitle(to);
      },
    },
  };
</script>

<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>

<style>
  /* sidemenu override */
  .bm-burger-button {
    position: fixed !important;
    top: 14px !important;
  }
  .bm-burger-bars,
  .bm-menu {
    @apply bg-red-700 !important;
    white-space: nowrap;
  }
  .bm-item-list > a:hover {
    @apply text-red-100 !important;
  }
  /* ヘッダーの高さを引いたmax height */
  .max-h {
    height: calc(100vh - 6rem);
  }

  .overlay {
    /* 要素を重ねた時の順番 */
    z-index: 40;

    /* 画面全体を覆う設定 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    /* 画面の中央に要素を表示させる設定 */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    @apply rounded z-50;
    width: 30%;
    padding: 1em;
    background: #fff;
  }
  .modal-footer {
    @apply mt-4;
  }

  /************************/
  /* multiselect override */
  /************************/
  .multiselect {
    @apply mt-1;
  }
  /* プルダウン内で未選択項目をhover時の背景色と文字色変更 */
  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    @apply bg-blue-100 text-gray-600;
  }
  /* 選択済タグの背景色と文字色変更 */
  .multiselect__tag {
    @apply bg-gray-200 text-gray-600;
  }
  /* 選択済タグの×マークの文字色変更 */
  .multiselect__tag-icon:after {
    @apply text-gray-800;
  }
  /* 検索欄のフォーカス時の囲いを消す */
  input.multiselect__input {
    @apply focus:ring focus:ring-white focus:shadow-none;
  }
  /* プルダウン内で選択済項目をhover時の背景色変更 */
  .multiselect__option--selected.multiselect__option--highlight,
  .multiselect__option--selected.multiselect__option--highlight:after,
  .multiselect__option--group-selected.multiselect__option--highlight,
  .multiselect__option--group-selected.multiselect__option--highlight:after {
    @apply bg-red-200;
  }
</style>
