/** ユーザ変更申請ステータス */
class UserUpdateReq {
    /** 未対応 */
    static OPEN = { code: "0", name: "未対応" };
    /** 対応済 */
    static CLOSED = { code: "1", name: "対応済" };
    /** すべて */
    static ALL = { code: "9", name: "すべて" };
    /**
     * ユーザステータスのコードから論理名を取得する
     * @param {string} code ユーザステータスのコード
     * @returns ユーザステータスの論理名
     */
    static getNameByCode(code) {
        let name = "";
        for (const key in this) {
            if (this[key].code === code) {
                name = this[key].name;
            }
        }
        return name;
    }
    /**
     * ステータスの一覧から配列を作成する
     * @returns ステータスの配列
     */
    static getRequestArray() {
        const array = [this.OPEN, this.CLOSED, this.ALL];
        return array;
    }
}
export { UserUpdateReq };
