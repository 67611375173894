import { Slide } from "vue3-burger-menu"; // import the CSS transitions you wish to use, in this case we are using `Slide`
import { UserAuth } from "../const/userAuthority";
export default {
    data() {
        return {
            isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
        };
    },
    components: {
        Slide, // Register your component
    },
};
