/** ファイル区分 */
class FileType {
    /** csvファイル */
    static CSV = { name: ".csv", regex: /^application\/.*csv.*/ };
    /** 画像ファイル(jpg, png) */
    static IMAGE = {
        name: ".jpg、.pngのいずれかの",
        regex: /^image\/(png|jpeg)/,
    };
}
export { FileType };
