import * as Vue from "vue";
import App from "./Top.vue";
import store from "./store";
// import axios from "axios";
import { router } from "./router";
import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Multiselect from "@suadelabs/vue3-multiselect";
// import VueAxios from "vue-axios";
import VTooltip from "v-tooltip";
library.add(fas);
const app = Vue.createApp(App);
app.use(router);
app.use(store);
// app.use(VueAxios);
app.use(VTooltip);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("multiselect", Multiselect);
app.mount("#app");
