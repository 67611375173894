import { Message } from "../const/message";
import { UserUpdateReq } from "../const/userUpdateReq";
import { CRM } from "../const/crm";
export default {
    name: "TemplateListTable",
    props: {
        searchedRequests: { type: Array, default: () => [] },
        isSysAdmin: {
            type: Boolean,
            default: () => false,
        },
        completeRequest: {
            type: Function,
            required: true,
        },
        getUpdateReqDetails: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            userUpdateReq: UserUpdateReq,
            showContent: false,
            pageRequestList: [],
            displayRequests: [],
            requestDetail: [],
            request: [],
            pageNum: 0,
            nowPage: 1,
            pagenation: [],
            selectedPage: [],
        };
    },
    methods: {
        calcPageNum(value) {
            // クリックされた値を現在のページに上書き
            this.nowPage = value;
            let firstPage = this.nowPage - 2;
            let lastPage = this.nowPage + 2;
            if (this.pageNum <= 5) {
                // ページの総数が5ページ以下の場合
                firstPage = 1;
                lastPage = this.pageNum;
            }
            else if (this.nowPage === 1 || this.nowPage === 2) {
                // 現在のページが1,2ページ目の場合
                firstPage = 1;
                lastPage = 5;
            }
            else if (this.nowPage === this.pageNum - 1 ||
                this.nowPage === this.pageNum) {
                // 現在のページが最終,最終の一つ前ページ目の場合
                firstPage = this.pageNum - 4;
                lastPage = this.pageNum;
            }
            this.pagenation = [];
            for (let i = firstPage; i <= lastPage; i++) {
                this.pagenation.push(i);
            }
        },
        isFormat(page) {
            // 追加するスタイル格納用配列初期化
            let addStyle = {};
            // 現在表示中のページかどうか
            if (page === this.nowPage) {
                // 現在表示中のページのスタイルを追加
                addStyle["color"] = "white";
                addStyle["backgroundColor"] = "gray";
            }
            // 現在表示中のページが１ページ目かどうか
            if (page === 1 && this.nowPage === 1) {
                addStyle["border-top-left-radius"] = "0.25rem";
                addStyle["border-bottom-left-radius"] = "0.25rem";
            }
            // 現在表示中のページが最終ページ目かどうか
            if (page === this.pageNum && this.nowPage === this.pageNum) {
                addStyle["border-top-right-radius"] = "0.25rem";
                addStyle["border-bottom-right-radius"] = "0.25rem";
            }
            this.selectedPage[page] = addStyle;
            return addStyle;
        },
        async openModal(request) {
            this.request = request;
            this.requestDetail = await this.getUpdateReqDetails(request.id);
            this.showContent = true;
        },
        closeModal() {
            this.request = [];
            this.requestDetail = [];
            this.showContent = false;
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
        },
        complate(requestId) {
            const msg = ["対象の変更申請を完了します。", "よろしいでしょうか？"];
            this.$store.commit("setMessages", msg);
            this.$store.commit("setOkBtnMsg", "はい");
            this.$store.commit("setCancelBtnMsg", "いいえ");
            this.$store.commit("setOkAction", () => {
                this.completeRequest(requestId);
                this.request = [];
                this.requestDetail = [];
                this.showContent = false;
            });
            this.$store.commit("setCancelAction", () => {
                return;
            });
            this.$store.commit("setIsModalOpen", true);
        },
        crmLink(customerId) {
            window.open(CRM.getCrmUrl(this.$store.state.loginId, customerId), "_blank", "noreferrer");
        },
    },
    watch: {
        searchedRequests(requests) {
            // ページごとの学校リスト初期化
            this.pageRequestList = [];
            // テーブルに表示する学校の配列を初期化
            this.displayRequests = [];
            // 現在ページの番号を初期化
            this.nowPage = 1;
            // ページネーション番号を初期化
            this.pagenation = [];
            // ページの表示件数ごとに全学校リストを分割
            while (requests.length)
                this.pageRequestList.push(requests.splice(0, Message.USERREQ.DISPLAY_NUMBER));
            // ページ数を取得
            this.pageNum = this.pageRequestList.length;
            // ページネーション番号のセット
            if (this.pageNum <= 5) {
                // ページ番号が5以下の場合、ページ番号のみのページネーション番号をセット
                for (let i = 1; i <= this.pageNum; i++) {
                    this.pagenation.push(i);
                }
            }
            else {
                // 5ページ目までのページネーション番号をセット
                this.pagenation = [1, 2, 3, 4, 5];
            }
            // 表示ページのデータ配列を更新
            this.displayRequests = this.pageRequestList[this.nowPage - 1];
        },
        nowPage: function () {
            // 表示ページのデータ配列を更新
            this.displayRequests = this.pageRequestList[this.nowPage - 1];
        },
    },
};
