import { Api } from "../axios";
import { CryptoUtils } from "../crypto";
const headers = {
    "Content-Type": "application/json;charset=utf-8",
};
const instance = new Api(process.env.VUE_APP_SERVER_HOST, headers);
/**
 * トークンリフレッシュ
 * @param {string} refreshToken リフレッシュトークン
 */
export const refreshToken = async function (refreshToken) {
    const params = {
        refreshToken: CryptoUtils.encryptoAes(refreshToken),
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
    };
    const response = await instance.post("/token-refresh", params);
    return response?.body;
};
/**
 * ログイン
 * @param {Function} callback コールバック関数
 * @param {string} crmUserId CRMユーザID
 * @param {string} password パスワード
 * @param {string} isDeviceChanged 端末変更確認フラグ
 */
export const login = async function (callback, crmUserId, password, isDeviceChanged) {
    const params = {
        crmUserId: crmUserId,
        password: password,
        isDeviceChanged: isDeviceChanged,
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
    };
    const response = await instance.post("/administrators/login", params);
    callback(response.body);
};
/**
 * 二段階認証送信
 * @param {Function} callback コールバック関数
 * @param {string} loginId ログインID
 * @param {string} oldPassword 現パスワード
 * @param {string} newPassword 新パスワード
 * @param {string} newPasswordConfirm 新パスワード（確認用）
 */
export const twoFactAuthentication = async function (callback, authCode, session, userType, crmUserId) {
    const params = {
        authCode: authCode,
        session: session,
        userType: userType,
        crmUserId: crmUserId,
    };
    const response = await instance.post("/two-fact-authentication", params);
    callback(response.body);
};
/**
 * パスワード更新
 * @param {Function} callback コールバック関数
 * @param {string} loginId ログインID
 * @param {string} oldPassword 現パスワード
 * @param {string} newPassword 新パスワード
 * @param {string} newPasswordConfirm 新パスワード（確認用）
 */
export const updatePassword = async function (callback, oldPassword, newPassword, newPasswordConfirm) {
    const params = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm,
        accessToken: CryptoUtils.encryptoAes(localStorage.getItem("aToken")),
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
    };
    const response = await instance.post("/password-change", params);
    callback(response.body);
};
/**
 * パスワードリセット
 * @param {Function} callback コールバック関数
 * @param {string} phoneNum 電話番号
 */
export const resetPassword = async function (callback, phoneNum) {
    const params = {
        phoneNum: phoneNum,
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
        isAdmin: true,
    };
    const response = await instance.post("/password-reset", params);
    callback(response.body);
};
/**
 * ユーザ変更申請検索
 * @param {?string} storeId 支店コード
 * @param {?string} statusCd ステータスコード
 */
export const searchCustomerChangeRequests = async function (storeId = null, customerNo = null, statusCd = null) {
    const params = {
        storeId: storeId,
        statusCd: statusCd,
        customerNo: customerNo,
    };
    const response = await instance.get("/customers/change-requests", params);
    return response?.body;
};
/**
 * ユーザ変更申請詳細取得
 * @param {string} id リクエストID
 */
export const getUpdateReqDetails = async function (id) {
    const params = {};
    const response = await instance.get("/customers/change-requests/" + id, params);
    return response?.body;
};
/**
 * ユーザ変更申請ステータス更新
 * @param {?string} storeId 支店コード
 * @param {?string} statusCd ステータスコード
 */
export const updateCustomerChangeRequest = async function (requestId) {
    const response = await instance.put("/customers/change-requests/" + requestId, null);
    return response?.body;
};
/**
 * テンプレート検索
 * @param {?string} divisionCode 事業部コード
 * @param {?string} templateId グループID
 * @param {string} usage 用途(0:リスト取得,1:一覧取得)
 */
export const searchTemplates = async function (templateId = null, divisionCode = null, usage) {
    const params = {
        templateId: templateId,
        divisionCode: divisionCode,
        usage: usage,
    };
    const response = await instance.get("/templates", params);
    return response?.body;
};
/**
 * テンプレート本文取得
 * @param {string} templateId グループID
 */
export const getTemplate = async function (templateId) {
    const params = {
        templateId: templateId,
    };
    const response = await instance.get("/templates/" + templateId, params);
    return response?.body;
};
/**
 * 事業部取得
 * @param {?string} code アプリケーションプロパティのコード
 */
export const getDivisions = async function (code = null) {
    const params = {
        code: code,
    };
    const response = await instance.get("/settings/divisions", params);
    return response?.body;
};
/**
 * テンプレート削除
 * @param {string} templateId グループID
 */
export const deleteTemplate = async function (templateId) {
    const params = {
        templateId: templateId,
    };
    const response = await instance.delete("/templates/" + templateId, params);
    return response?.body;
};
/**
 * 利用ユーザ検索
 * @param {?string} customerNo お客様番号
 */
export const searchCustomers = async function (customerNo = null, registDateStart = null, registDateEnd = null) {
    const params = {
        customerNo: customerNo,
        registDateStart: registDateStart,
        registDateEnd: registDateEnd,
    };
    const response = await instance.get("/customers", params);
    return response?.body;
};
/**
 * 管理ユーザ検索
 * @param {?string} crmUserId CRMユーザID
 * @param {?string} storeId 店舗ID
 */
export const searchAdministrators = async function (crmUserId = null, storeId = null) {
    const params = {
        crmUserId: crmUserId,
        storeId: storeId,
    };
    const response = await instance.get("/administrators", params);
    return response?.body;
};
/**
 * 管理ユーザ削除
 * @param {string} crmUserId CRMユーザID
 */
export const deleteAdministrator = async function (crmUserId) {
    const params = {};
    const response = await instance.delete("/administrators/" + crmUserId, params);
    return response?.body;
};
/**
 * 利用ユーザ情報更新
 * @param {string} phoneNum 電話番号
 * @param {string} templateCheckFlg テンプレートプレビュー閲覧の許可
 * @param {string} isLocked アカウントロック
 * @param {?string} isLockedComment アカウントロック時のコメント
 */
export const udapteCustomer = async function (phoneNum, templateCheckFlg, isLocked, isLockedComment = null) {
    const params = {
        phoneNum: phoneNum,
        templateCheckFlg: templateCheckFlg,
        isLocked: isLocked,
        isLockedComment: isLockedComment,
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
    };
    const response = await instance.put("/customers", params);
    return response?.body;
};
/**
 * 配信状況検索
 * @param {?string} templateId グループID
 * @param {?string} releaseDateStart 配信日付開始範囲
 * @param {?string} releaseDateEnd 配信日付開始範囲
 * @param {?string} infoStatus ステータス
 * @param {?string} divisionCode 事業部コード
 */
export const searchReleasestatus = async function (templateId = null, releaseDateStart = null, releaseDateEnd = null, infoStatus = null, divisionCode) {
    const params = {
        templateId: templateId,
        releaseDateStart: releaseDateStart,
        releaseDateEnd: releaseDateEnd,
        infoStatus: infoStatus,
        divisionCode: divisionCode,
    };
    const response = await instance.get("/push-notifications", params);
    return response?.body;
};
/**
 * 配信取消
 * @param {?string} id releaseStatusの識別子
 */
export const deletePushNotification = async function (id) {
    const params = {};
    const response = await instance.delete("/push-notifications/" + id, params);
    return response?.body;
};
/**
 * プッシュ通知配信先取得
 * @param {?string} id releaseStatusの識別子
 */
export const getPushDestinations = async function (id) {
    const params = {};
    const response = await instance.get("/push-destinations/" + id, params);
    return response?.body;
};
/**
 * プッシュ通知CSVアップロード
 * @param {Function} callback コールバック関数
 * @param {string} templateId グループID
 * @param {string} releaseDate 配信日付
 * @param {string} divisionCode 事業部
 * @param {string} releaseStatus 配信ステータス
 * @param {File} csv 電話番号リストのCSVファイル
 */
export const uploadPushNotification = async function (callback, templateId, releaseDate, divisionCode, csv) {
    const formData = new FormData();
    formData.append("csv", csv);
    formData.append("templateId", templateId);
    formData.append("releaseDate", releaseDate);
    formData.append("divisionCode", divisionCode);
    const response = await instance.post("/push-notifications/csv-upload", formData);
    callback(response.body);
};
/**
 * 全ユーザ向けプッシュ通知登録
 * @param {Function} callback コールバック関数
 * @param {string} templateId グループID
 * @param {string} releaseDate 配信日付
 * @param {string} divisionCode 事業部
 * @param {string} releaseStatus 配信ステータス
 */
export const uploadPushNotificationAll = async function (callback, templateId, releaseDate, divisionCode) {
    const requestData = {
        templateId: templateId,
        releaseDate: releaseDate,
        divisionCode: divisionCode,
    };
    const response = await instance.post("/push-notifications/push-all-customer", requestData);
    callback(response.body);
};
/**
 * 管理ユーザ取得
 * @param {Function} callback コールバック関数
 * @param {string} crmUserId CRMユーザID
 */
export const getAdministrator = async function (callback, crmUserId) {
    const params = {
        crmUserId: crmUserId,
    };
    const response = await instance.get("/administrators/" + crmUserId, params);
    callback(response.body);
};
/**
 * 管理ユーザ更新
 * @param {Function} callback コールバック関数
 * @param {string} crmUserId CRMユーザID
 * @param {string} userName 管理ユーザ名
 * @param {string} storeId 店舗ID
 * @param {string} phoneNum 電話番号
 * @param {string} authType ユーザ区分
 */
export const updateAdministrator = async function (callback, crmUserId, userName, storeId, phoneNum, authType) {
    const params = {
        crmUserId: crmUserId,
        userName: userName,
        storeId: storeId,
        phoneNum: phoneNum,
        authType: authType,
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
    };
    const response = await instance.put("/administrators/" + crmUserId, params);
    callback(response.body);
};
/**
 * プッシュ通知テンプレート登録
 * @param {Function} callback コールバック関数
 * @param {string} templateId グループID
 * @param {string} title タイトル
 * @param {string} sub プッシュ通知文
 * @param {string} releaseTime 配信時刻(hh:mm)
 * @param {string} divisionCode 事業部コード
 */
export const registTemplate = async function (callback, templateId, title, sub, body, releaseTime, divisionCode, crmGetFlg) {
    const params = {
        templateId: templateId,
        title: title,
        sub: sub,
        body: body,
        releaseTime: releaseTime,
        divisionCode: divisionCode,
        crmGetFlg: crmGetFlg,
    };
    const response = await instance.post("/templates", params);
    callback(response.body);
};
/**
 * プッシュ通知テンプレート更新
 * @param {Function} callback コールバック関数
 * @param {string} templateId グループID
 * @param {string} title タイトル
 * @param {string} body 本文
 */
export const updateTemplate = async function (callback, templateId, title, sub, body, releaseTime, crmGetFlg) {
    const params = {
        templateId: templateId,
        title: title,
        sub: sub,
        body: body,
        releaseTime: releaseTime,
        crmGetFlg: crmGetFlg,
    };
    const response = await instance.put("/templates/" + templateId, params);
    callback(response.body);
};
/**
 * 管理ユーザ登録
 * @param {Function} callback コールバック関数
 * @param {string} crmUserId CRMユーザID
 * @param {string} userName 管理ユーザ名
 * @param {string} storeId 店舗ID
 * @param {string} phoneNum 電話番号
 * @param {?string} authType ユーザ区分
 */
export const registAdministrator = async function (callback, crmUserId, userName, storeId, phoneNum, authType) {
    const params = {
        crmUserId: crmUserId,
        userName: userName,
        storeId: storeId,
        phoneNum: phoneNum,
        authType: authType,
        commonKey: CryptoUtils.commonKeyEncryptoRsa(),
    };
    const response = await instance.post("/administrators", params);
    callback(response.body);
};
