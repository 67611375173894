import { Message } from "../const/message";
import { AccountLock } from "../const/accountLock";
import { TemplatePreview } from "../const/templatePreview";
import { CRM } from "../const/crm";
export default {
    name: "UserListTable",
    props: {
        searchedCustomers: { type: Array, default: () => [] },
        searchedAdministrators: { type: Array, default: () => [] },
        isSysAdmin: {
            type: Boolean,
            default: () => false,
        },
        deleteAdministrator: {
            type: Function,
            required: true,
        },
        udapteCustomer: {
            type: Function,
            required: true,
        },
        isAdminList: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            accountLock: AccountLock,
            templatePreview: TemplatePreview,
            pageCustomerList: [],
            displayCustomers: [],
            pageAdministratorList: [],
            displayAdministrators: [],
            customer: [],
            administrator: [],
            showModalLift: false,
            showModalLock: false,
            showModalTemplateOk: false,
            showModalTemplateNg: false,
            showModalDelete: false,
            pageNum: 0,
            nowPage: 1,
            pagenation: [],
            selectedPage: [],
        };
    },
    methods: {
        calcPageNum(value) {
            // クリックされた値を現在のページに上書き
            this.nowPage = value;
            let firstPage = this.nowPage - 2;
            let lastPage = this.nowPage + 2;
            if (this.pageNum <= 5) {
                // ページの総数が5ページ以下の場合
                firstPage = 1;
                lastPage = this.pageNum;
            }
            else if (this.nowPage === 1 || this.nowPage === 2) {
                // 現在のページが1,2ページ目の場合
                firstPage = 1;
                lastPage = 5;
            }
            else if (this.nowPage === this.pageNum - 1 ||
                this.nowPage === this.pageNum) {
                // 現在のページが最終,最終の一つ前ページ目の場合
                firstPage = this.pageNum - 4;
                lastPage = this.pageNum;
            }
            this.pagenation = [];
            for (let i = firstPage; i <= lastPage; i++) {
                this.pagenation.push(i);
            }
        },
        isFormat(page) {
            // 追加するスタイル格納用配列初期化
            let addStyle = {};
            // 現在表示中のページかどうか
            if (page === this.nowPage) {
                // 現在表示中のページのスタイルを追加
                addStyle["color"] = "white";
                addStyle["backgroundColor"] = "gray";
            }
            // 現在表示中のページが１ページ目かどうか
            if (page === 1 && this.nowPage === 1) {
                addStyle["border-top-left-radius"] = "0.25rem";
                addStyle["border-bottom-left-radius"] = "0.25rem";
            }
            // 現在表示中のページが最終ページ目かどうか
            if (page === this.pageNum && this.nowPage === this.pageNum) {
                addStyle["border-top-right-radius"] = "0.25rem";
                addStyle["border-bottom-right-radius"] = "0.25rem";
            }
            this.selectedPage[page] = addStyle;
            return addStyle;
        },
        openModalLock(customer) {
            this.customer = customer;
            this.showModalLock = true;
        },
        openModalLift(customer) {
            this.customer = customer;
            this.showModalLift = true;
        },
        openModalTemplateOk(customer) {
            this.customer = customer;
            this.showModalTemplateOk = true;
        },
        openModalTemplateNg(customer) {
            this.customer = customer;
            this.showModalTemplateNg = true;
        },
        openModalDelete(administrator) {
            this.administrator = administrator;
            this.showModalDelete = true;
        },
        closeModalLock() {
            this.customer = [];
            this.showModalLock = false;
        },
        closeModalLift() {
            this.customer = [];
            this.showModalLift = false;
        },
        closeModalTemplateOk() {
            this.customer = [];
            this.showModalTemplateOk = false;
        },
        closeModalTemplateNg() {
            this.customer = [];
            this.showModalTemplateNg = false;
        },
        closeModalDelete() {
            this.administrator = [];
            this.showModalDelete = false;
        },
        lockAccount(phoneNum, lockCd, isLokedComment) {
            // アカウントロック、アカウントロック解除する
            this.udapteCustomer(phoneNum, null, lockCd, isLokedComment);
            this.customer = [];
            this.showModalLock = false;
            this.showModalLift = false;
        },
        TemplatePreviewPermission(phoneNum, templateCheckFlg) {
            // テンプレートプレビュー閲覧の許可、禁止
            this.udapteCustomer(phoneNum, templateCheckFlg, null, null);
            this.customer = [];
            this.showModalTemplateOk = false;
            this.showModalTemplateNg = false;
        },
        deleteAdmin() {
            // 管理ユーザを削除する
            this.deleteAdministrator(this.administrator.crmUserId);
            this.administrator = [];
            this.showModalDelete = false;
        },
        crmLink(customerId) {
            window.open(CRM.getCrmUrl(this.$store.state.loginId, customerId), "_blank", "noreferrer");
        },
    },
    watch: {
        searchedCustomers(customers) {
            // ページごとの利用ユーザリスト初期化
            this.pageCustomerList = [];
            // テーブルに表示する利用ユーザの配列を初期化
            this.displayCustomers = [];
            // 現在ページの番号を初期化
            this.nowPage = 1;
            // ページネーション番号を初期化
            this.pagenation = [];
            // ページの表示件数ごとに全学校リストを分割
            while (customers.length)
                this.pageCustomerList.push(customers.splice(0, Message.USERREQ.DISPLAY_NUMBER));
            // ページ数を取得
            this.pageNum = this.pageCustomerList.length;
            // ページネーション番号のセット
            if (this.pageNum <= 5) {
                // ページ番号が5以下の場合、ページ番号のみのページネーション番号をセット
                for (let i = 1; i <= this.pageNum; i++) {
                    this.pagenation.push(i);
                }
            }
            else {
                // 5ページ目までのページネーション番号をセット
                this.pagenation = [1, 2, 3, 4, 5];
            }
            // 表示ページのデータ配列を更新
            this.displayCustomers = this.pageCustomerList[this.nowPage - 1];
        },
        searchedAdministrators(administrators) {
            // ページごとの利用ユーザリスト初期化
            this.pageAdministratorList = [];
            // テーブルに表示する利用ユーザの配列を初期化
            this.displayAdministrators = [];
            // 現在ページの番号を初期化
            this.nowPage = 1;
            // ページネーション番号を初期化
            this.pagenation = [];
            // ページの表示件数ごとに全学校リストを分割
            while (administrators.length)
                this.pageAdministratorList.push(administrators.splice(0, Message.USERREQ.DISPLAY_NUMBER));
            // ページ数を取得
            this.pageNum = this.pageAdministratorList.length;
            // ページネーション番号のセット
            if (this.pageNum <= 5) {
                // ページ番号が5以下の場合、ページ番号のみのページネーション番号をセット
                for (let i = 1; i <= this.pageNum; i++) {
                    this.pagenation.push(i);
                }
            }
            else {
                // 5ページ目までのページネーション番号をセット
                this.pagenation = [1, 2, 3, 4, 5];
            }
            // 表示ページのデータ配列を更新
            this.displayAdministrators =
                this.pageAdministratorList[this.nowPage - 1];
        },
        nowPage: function () {
            // 表示ページのデータ配列を更新
            this.displayCustomers = this.pageCustomerList[this.nowPage - 1];
            this.displayAdministrators =
                this.pageAdministratorList[this.nowPage - 1];
        },
    },
};
