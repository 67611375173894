/** ユーザ区分 */
class UserAuth {
    /** 店舗管理者 */
    static STORE_ADMIN = { code: "1", name: "店舗管理者" };
    /** 本部管理者 */
    static SYSTEM_ADMIN = { code: "9", name: "本部管理者" };
    /**
     * ユーザ区分のコードから論理名を取得する
     * @param {string} code ユーザ区分のコード
     * @returns ユーザ区分の論理名
     */
    static getNameByCode(code) {
        let name = "";
        for (const key in this) {
            if (this[key].code === code) {
                name = this[key].name;
            }
        }
        return name;
    }
}
export { UserAuth };
