import { ReleaseStatus } from "../const/releaseStatus";
import { Message } from "../const/message";
import { CRM } from "../const/crm";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
export default {
    name: "TemplateListTable",
    props: {
        searchedReleaseStatuses: { type: Array, default: () => [] },
        releaseCancel: {
            type: Function,
            required: true,
        },
        getPushDestinations: {
            type: Function,
            required: true,
        },
        getTemplate: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            showContentDetail: false,
            showContentReleaseList: false,
            showModalDelete: false,
            releaseStatus: ReleaseStatus,
            pageReleaseStatusList: [],
            displayReleaseStatuses: [],
            releaseDetail: [],
            destinationsCustomerList: [],
            pageNum: 0,
            nowPage: 1,
            pagenation: [],
            selectedPage: [],
        };
    },
    components: {
        QuillEditor,
    },
    methods: {
        calcPageNum(value) {
            // クリックされた値を現在のページに上書き
            this.nowPage = value;
            let firstPage = this.nowPage - 2;
            let lastPage = this.nowPage + 2;
            if (this.pageNum <= 5) {
                // ページの総数が5ページ以下の場合
                firstPage = 1;
                lastPage = this.pageNum;
            }
            else if (this.nowPage === 1 || this.nowPage === 2) {
                // 現在のページが1,2ページ目の場合
                firstPage = 1;
                lastPage = 5;
            }
            else if (this.nowPage === this.pageNum - 1 ||
                this.nowPage === this.pageNum) {
                // 現在のページが最終,最終の一つ前ページ目の場合
                firstPage = this.pageNum - 4;
                lastPage = this.pageNum;
            }
            this.pagenation = [];
            for (let i = firstPage; i <= lastPage; i++) {
                this.pagenation.push(i);
            }
        },
        isFormat(page) {
            // 追加するスタイル格納用配列初期化
            let addStyle = {};
            // 現在表示中のページかどうか
            if (page === this.nowPage) {
                // 現在表示中のページのスタイルを追加
                addStyle["color"] = "white";
                addStyle["backgroundColor"] = "gray";
            }
            // 現在表示中のページが１ページ目かどうか
            if (page === 1 && this.nowPage === 1) {
                addStyle["border-top-left-radius"] = "0.25rem";
                addStyle["border-bottom-left-radius"] = "0.25rem";
            }
            // 現在表示中のページが最終ページ目かどうか
            if (page === this.pageNum && this.nowPage === this.pageNum) {
                addStyle["border-top-right-radius"] = "0.25rem";
                addStyle["border-bottom-right-radius"] = "0.25rem";
            }
            this.selectedPage[page] = addStyle;
            return addStyle;
        },
        async openModalDetail(releaseDetail) {
            let body = await this.getTemplate(releaseDetail.templateId);
            this.releaseDetail = releaseDetail;
            this.$refs.myEditor.setHTML(body);
            this.showContentDetail = true;
        },
        closeModalDetail() {
            this.releaseDetail = [];
            this.$refs.myEditor.setHTML("");
            this.showContentDetail = false;
        },
        openModalDelete(releaseDetail) {
            this.releaseDetail = releaseDetail;
            this.showModalDelete = true;
        },
        closeModalDelete() {
            if (!this.showContentDetail) {
                this.releaseDetail = [];
                this.$refs.myEditor.setHTML("");
            }
            this.showModalDelete = false;
        },
        async openModalReleaseList(id) {
            this.destinationsCustomerList = await this.getPushDestinations(id);
            this.showContentReleaseList = true;
        },
        closeModalReleaseList() {
            this.destinationsCustomerList = [];
            this.showContentReleaseList = false;
        },
        async cancel() {
            await this.releaseCancel(this.releaseDetail.id);
            this.showModalDelete = false;
            this.closeModalDetail();
        },
        crmLink(customerId) {
            window.open(CRM.getCrmUrl(this.$store.state.loginId, customerId), "_blank", "noreferrer");
        },
    },
    watch: {
        searchedReleaseStatuses(ReleaseStatus) {
            // ページごとの学校リスト初期化
            this.pageReleaseStatusList = [];
            // テーブルに表示する学校の配列を初期化
            this.displayReleaseStatuses = [];
            // 現在ページの番号を初期化
            this.nowPage = 1;
            // ページネーション番号を初期化
            this.pagenation = [];
            // ページの表示件数ごとに全学校リストを分割
            while (ReleaseStatus.length)
                this.pageReleaseStatusList.push(ReleaseStatus.splice(0, Message.USERREQ.DISPLAY_NUMBER));
            // ページ数を取得
            this.pageNum = this.pageReleaseStatusList.length;
            // ページネーション番号のセット
            if (this.pageNum <= 5) {
                // ページ番号が5以下の場合、ページ番号のみのページネーション番号をセット
                for (let i = 1; i <= this.pageNum; i++) {
                    this.pagenation.push(i);
                }
            }
            else {
                // 5ページ目までのページネーション番号をセット
                this.pagenation = [1, 2, 3, 4, 5];
            }
            // 表示ページのデータ配列を更新
            this.displayReleaseStatuses =
                this.pageReleaseStatusList[this.nowPage - 1];
        },
        nowPage: function () {
            // 表示ページのデータ配列を更新
            this.displayReleaseStatuses =
                this.pageReleaseStatusList[this.nowPage - 1];
        },
    },
};
