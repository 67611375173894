import { getDivisions, searchTemplates, deleteTemplate, getTemplate, } from "../api";
import Title from "../components/TheTitle.vue";
import Form from "../components/TemplateListForm.vue";
import Table from "../components/TemplateListTable.vue";
import { UserAuth } from "../const/userAuthority";
import { Authorize } from "../utils/authorize";
export default {
    data() {
        return {
            searchedDivisions: [],
            searchedTemplates: [],
            searchedTemplateTitle: [],
            templateTitleList: [],
            isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
            errMsgs: [],
            templateId: null,
            divisionCode: null,
        };
    },
    name: "TemplateList",
    components: {
        Title,
        Form,
        Table,
    },
    created: async function () {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority === UserAuth.SYSTEM_ADMIN.code) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 事業部一覧全件取得
            const divisionsRspBody = await getDivisions();
            this.searchedDivisions = divisionsRspBody.detail;
            // テンプレートのタイトル一覧を全件取得
            const templatesTitleRspBody = await searchTemplates(null, null, "0");
            this.searchedTemplateTitle = templatesTitleRspBody.detail;
            this.templateTitleList = this.searchedTemplateTitle;
        }
        else {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
    },
    methods: {
        async search(templateId, divisionCode) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await searchTemplates(templateId, divisionCode, "1");
            if (rspBody.result.messages.length) {
                this.searchedTemplates = [];
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 成功した場合、取得したレコードを渡す
                this.searchedTemplates = rspBody.detail;
                // 検索条件を保持
                this.templateId = templateId;
                this.divisionCode = divisionCode;
            }
        },
        async templateFilter(divisionCode) {
            this.templateTitleList = [];
            if (divisionCode === "") {
                this.templateTitleList = this.searchedTemplateTitle;
            }
            else {
                this.searchedTemplateTitle.forEach((value) => {
                    if (value.divisionCode === divisionCode) {
                        this.templateTitleList.push(value);
                    }
                });
            }
        },
        async remove(templateId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await deleteTemplate(templateId);
            if (rspBody.result.messages.length) {
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 削除押下後、再度フォームの検索条件でレコードを取得
                const templatesRspBody = await searchTemplates(this.templateId, this.divisionCode, "1");
                // 成功した場合、取得したレコードを渡す
                this.searchedTemplates = templatesRspBody.detail;
            }
        },
        async getBody(templateId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await getTemplate(templateId);
            return rspBody.detail.body;
        },
    },
};
