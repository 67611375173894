import Title from "../components/TheTitle.vue";
import { registAdministrator, getAdministrator } from "../api";
import ErrMsgCard from "../components/ErrorMssageCard.vue";
import { UserAuth } from "../const/userAuthority";
import { Message } from "../const/message";
import { Validation } from "../utils/validation";
import { Authorize } from "../utils/authorize";
import { StoreCd } from "../const/storeCd";
import { CryptoUtils } from "../crypto";
export default {
    name: "UserManageRegester",
    data() {
        return {
            crmUserId: {
                key: "CRMユーザID",
                value: "",
            },
            userName: { key: "氏名", value: "" },
            storeId: { key: "支店コード", value: "" },
            phoneNum: { key: "電話番号", value: "" },
            authType: { key: "ユーザ区分", value: UserAuth.STORE_ADMIN.code },
            systemAdmin: UserAuth.SYSTEM_ADMIN.code,
            storeAdmin: UserAuth.STORE_ADMIN.code,
            adminStoreCd: StoreCd.SYSTEM_ADMIN.code,
            errMsgs: [],
            isUnsent: true,
        };
    },
    components: {
        Title,
        ErrMsgCard,
    },
    async created() {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority !== UserAuth.STORE_ADMIN.code &&
            authority !== UserAuth.SYSTEM_ADMIN.code) {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
        if (Date.now() / 1000 > this.$store.state.tokenExp) {
            if (!(await Authorize.isAuth())) {
                // 認証失敗：ログイン画面へ遷移
                this.$router.push({
                    name: "login",
                    query: { redirect: this.$route.path },
                });
                return false;
            }
        }
    },
    methods: {
        async register() {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 本部管理者の場合、店舗IDは固定にする(69)
            if (this.authType.value === UserAuth.SYSTEM_ADMIN.code) {
                this.storeId.value = this.adminStoreCd;
            }
            // バリデーション定義作成;
            let validationArray = [];
            // バリデーションチェック実施
            this.errMsgs = validationArray.concat(Validation.requiredInput(this.crmUserId), Validation.requiredInput(this.userName), Validation.digitsMax(this.userName, 50), Validation.requiredInput(this.storeId), Validation.digitsMax(this.storeId, 4), Validation.requiredInput(this.phoneNum), Validation.fixedLength(this.phoneNum, 11), Validation.isPhoneNumFormat(this.phoneNum));
            // CRMユーザIDがすでに存在しないか確認
            await getAdministrator((rspBody) => {
                // CRMユーザIDが重複していた場合
                if (rspBody.detail.phoneNum) {
                    this.errMsgs = validationArray.concat(Message.ID_EXIST_CRM_USER);
                }
            }, this.crmUserId.value);
            if (this.errMsgs.length) {
                // エラーメッセージがある場合は処理を中断
                return false;
            }
            // 登録確認ポップアップを表示
            this.$store.commit("setMessages", [
                Message.generateMessage(Message.CONFIRM, [
                    Message.PROC_TYPE.REGISTER,
                ]),
            ]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", async () => {
                // ユーザ名、電話番号の暗号化
                let userName = CryptoUtils.encryptoAes(this.userName.value);
                let phoneNum = CryptoUtils.encryptoAes(this.phoneNum.value);
                // 管理ユーザ登録API呼び出し
                registAdministrator((rspBody) => {
                    if (rspBody.result.code === 0) {
                        // 成功した場合ユーザ一覧画面へ遷移
                        this.toUserList();
                        // 登録成功ポップアップ表示;
                        this.$store.commit("setMessages", [
                            Message.generateMessage(Message.COMPLETED, [
                                Message.PROC_TYPE.REGISTER,
                            ]),
                        ]);
                        this.$store.commit("setIsModalInfo", true);
                        this.$store.commit("setCancelBtnHide", true);
                        this.$store.commit("setIsModalOpen", true);
                    }
                    else {
                        // 失敗した場合、エラー内容を表示
                        this.errMsgs = rspBody.result.messages;
                    }
                }, this.crmUserId.value, userName, this.storeId.value, phoneNum, this.authType.value);
                this.isUnsent = false;
            });
        },
        toUserList() {
            this.$router.push({
                name: "user",
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isUnsent && to.name != "maintenance") {
            this.$store.commit("setMessages", [Message.INPUT_VALUE_CLEAR]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", function () {
                next();
            });
            this.$store.commit("setCancelAction", function () {
                next(false);
            });
        }
        else {
            next();
        }
    },
};
