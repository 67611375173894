class Cookie {
    /** Cookieの有効期限(秒) */
    static expireMinuits = {
        token: 3600,
        clientId: 63072000, // 2年
    };
    static path = "path=/";
    /**
     * Cookieにtokenをセット
     * @param {String} idToken IDトークン
     */
    static setToken(idToken) {
        document.cookie =
            "token=" +
                idToken +
                "; max-age=" +
                this.expireMinuits.token +
                ";" +
                this.path;
    }
    /**
     * Cookieからtokenを削除
     */
    static deleteToken() {
        document.cookie = "token=; path=/; max-age=-1";
    }
    /**
     * CookieにクライアントIDをセット
     * @param {String} clientId クライアントID
     */
    static setClientId(clientId) {
        document.cookie =
            "clientId=" +
                clientId +
                "; max-age=" +
                this.expireMinuits.clientId +
                ";" +
                this.path;
    }
    /**
     * CookieからクライアントIDを削除
     */
    static deleteClientId() {
        document.cookie = "clientId=; max-age=0";
    }
    /**
     * CookieからクライアントIDを取得する
     * @returns クライアントID（存在しなければ空文字）
     */
    static getClientId() {
        const arr = this.getCookieArray();
        return arr["clientId"] ? arr["clientId"] : "";
    }
    /**
     * Cookieを取得して連想配列に格納
     */
    static getCookieArray() {
        const arr = [];
        if (document.cookie != "") {
            const tmp = document.cookie.split("; ");
            for (let i = 0; i < tmp.length; i++) {
                const data = tmp[i].split("=");
                arr[data[0]] = decodeURIComponent(data[1]);
            }
        }
        return arr;
    }
}
export { Cookie };
