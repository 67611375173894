import { refreshToken } from "../api";
import Store from "../store";
import { Cookie } from "./cookie";
class Authorize {
    /**
     * 認証可否を判断
     * @return 認証OK: true, 認証NG: false
     */
    static async isAuth() {
        // 認証API呼び出し
        const rspBody = await refreshToken(Store.state.rToken);
        if (rspBody.result.code === 0) {
            // 認証OKの場合はIDトークンとアクセストークンを更新
            const detail = rspBody.detail;
            localStorage.setItem("iToken", detail.idToken);
            localStorage.setItem("aToken", detail.accessToken);
            // cookieにIDトークンをセット
            Cookie.setToken(detail.idToken);
            // storeのtokenExpを更新
            Store.commit("setTokenExp", detail.tokenExp);
            // 認証成功: true を返却
            return true;
        }
        else {
            // storeに保持している情報を初期化
            localStorage.removeItem("ops-front-app");
            // localStrageに保持している情報を初期化
            localStorage.removeItem("iToken");
            localStorage.removeItem("aToken");
            // cookieを削除
            Cookie.deleteToken();
            // 認証失敗: false を返却
            return false;
        }
    }
}
export { Authorize };
