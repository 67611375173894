<template>
  <div class="antialiased">
    <div class="mt-3 mb-1">
      <div class="flex md:flex-row flex-col">
        <div class="flex flex-row mb-2 md:mb-0">
          <div class="items-start" v-if="isSysAdmin">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              ユーザ種別
            </p>
            <select
              v-model="userType"
              class="h-12 w-28 text-sm rounded-l border border-r-0 bg-white border-gray-400 text-gray-700 py-1 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              v-on:change.self="changeUserListType(userType.code)"
            >
              <option
                v-for="userType in userListType"
                :key="userType.code"
                v-bind:value="userType"
              >
                {{ userType.name }}
              </option>
            </select>
          </div>
          <div class="flex">
            <div class="relative" v-if="isAdminList">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ crmUserId.name }}
              </p>
              <input
                v-model="crmUserId.value"
                type="text"
                class="form-middle"
                :style="{
                  'border-top-right-radius': '0px',
                  'border-bottom-right-radius': '0px',
                }"
              />
            </div>
            <div class="relative" v-if="isAdminList">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ storeId.name }}
              </p>
              <input v-model="storeId.value" type="text" class="form-right" />
            </div>
            <div class="flex" v-else>
              <div class="relative">
                <p class="text-sm">
                  <font-awesome-icon icon="caret-down" class="ml-1" />
                  登録日(開始日)
                </p>
                <input
                  v-model="registDateStart.value"
                  type="date"
                  class="form-middle"
                  :style="{
                    'border-top-right-radius': '0px',
                    'border-bottom-right-radius': '0px',
                  }"
                />
              </div>
              <div class="relative">
                <p class="text-sm">
                  <font-awesome-icon icon="caret-down" class="ml-1" />
                  登録日(終了日)
                </p>
                <input
                  v-model="registDateEnd.value"
                  type="date"
                  class="form-middle"
                  :style="{
                    'border-top-right-radius': '0px',
                    'border-bottom-right-radius': '0px',
                  }"
                />
              </div>
              <div class="relative">
                <p class="text-sm">
                  <font-awesome-icon icon="caret-down" class="ml-1" />
                  {{ customerNo.name }}
                </p>
                <input
                  type="text"
                  v-model="customerNo.value"
                  class="form-right"
                />
              </div>
            </div>
          </div>

          <div class="mt-5">
            <div class="ml-4 inline-block" v-tooltip="{ content: '検索' }">
              <button v-on:click="search" class="btn-search btn-circle">
                <font-awesome-icon icon="search" class="" />
              </button>
            </div>

            <div class="ml-2 inline-block" v-tooltip="{ content: 'クリア' }">
              <button v-on:click="clear" class="btn-gray btn-circle">
                <font-awesome-icon icon="backspace" class="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { UserListType } from "../const/userListType";
  export default {
    name: "UserListForm",
    props: {
      cusList: {
        type: Array,
        default: () => [],
      },
      customerHandleSearch: {
        type: Function,
        required: true,
      },
      administratorHandleSearch: {
        type: Function,
        required: true,
      },
      isSysAdmin: {
        type: Boolean,
        default: () => false,
      },
      changeUserListType: {
        type: Function,
        required: true,
      },
      isAdminList: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        customerNo: { name: "お客様番号", value: "" },
        crmUserId: { name: "ユーザID", value: "" },
        storeId: { name: "支店コード", value: "" },
        userListType: UserListType.getList(),
        userType: { code: "0", name: "利用者" },
        registDateStart: { name: "登録日開始範囲", value: "" },
        registDateEnd: { name: "登録日終了範囲", value: "" },
      };
    },
    methods: {
      search() {
        if (this.userType.code == UserListType.ADMIN.code) {
          // 管理ユーザ検索
          const crmUserId = this.crmUserId.value ?? "";
          const storeId = this.storeId.value ?? "";
          this.administratorHandleSearch(crmUserId, storeId);
        } else if (this.userType.code == UserListType.CUSTOMER.code) {
          // 利用ユーザ検索
          let registDateStart = this.registDateStart.value ?? "";
          let registDateEnd = this.registDateEnd.value ?? "";
          const customerNo = this.customerNo.value ?? "";
          this.customerHandleSearch(customerNo, registDateStart, registDateEnd);
        }
      },
      clear() {
        Object.assign(this.$data, this.$options.data());
        this.changeUserListType(UserListType.CUSTOMER.code);
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  input[type="text"] {
    @apply mt-0 px-3 xl:rounded-l-none focus:border-gray-400;
    line-height: 28px;
    height: 48px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  input[type="date"] {
    @apply mt-0 px-3 xl:rounded-l-none focus:border-gray-400;
    line-height: 28px;
    height: 48px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  /* @media (min-width: 1280px) {
    input[type="text"] {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  } */

  .form-left {
    @apply h-12 
         py-2 px-4
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         rounded-l-md
         focus:ring-0;
  }

  .form-middle {
    @apply h-12 
         py-2 px-4 pr-8
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         focus:ring-0;
  }
  .form-right {
    @apply h-12 
         py-2 px-4 pr-8
         leading-tight
         text-sm text-gray-700
         border border-gray-400
         rounded-r-md
         focus:ring-0;
  }
  .btn {
    @apply font-semibold w-24 rounded py-2 px-2 text-sm focus:outline-none focus:ring focus:ring-opacity-50;
  }
  .btn-short {
    @apply h-8 py-1 px-4 w-28;
  }
  input[type="checkbox"] {
    @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
  }
  select {
    @apply focus:ring-0;
  }
</style>
