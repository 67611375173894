<template>
  <div class="my-12 bg-blue-200 p-4 rounded flex items-start text-blue-600 shadow-lg mx-auto max-w-lg">
    <div class="text-lg">
      <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-5 pt-1" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm.25,5a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12.25,5ZM14.5,18.5h-4a1,1,0,0,1,0-2h.75a.25.25,0,0,0,.25-.25v-4.5a.25.25,0,0,0-.25-.25H10.5a1,1,0,0,1,0-2h1a2,2,0,0,1,2,2v4.75a.25.25,0,0,0,.25.25h.75a1,1,0,1,1,0,2Z"
        ></path>
      </svg>
    </div>
    <div class=" px-4">
      <h3 class="text-blue-800 font-semibold tracking-wider">
        ただいまメンテナンス中です
      </h3>
    </div>
  </div>
  <div class="grid gap-10 grid-cols-1 text-center">
    <p>ただいまシステムメンテナンスを実施しております。</p>
    <p>
      大変ご迷惑をおかけして申し訳ございませんが、<br>
      メンテナンス終了まで今しばらくお待ちください。
    </p>
    <p>作業終了次第サービスを再開いたします。</p>
    <p><b>※メンテナンス画面が繰り返し表示される場合は、管理者にお問い合わせください。</b></p>
    <div class="flex justify-center mt-2">
      <button
        @click="$router.go(-1)"
        name="back"
        class="button btn-gray w-full"
      >
        戻る
      </button>
    </div>
  </div>
</template>

<style scoped>
.button {
  @apply font-semibold w-32 rounded py-2 px-2 focus:outline-none focus:ring focus:ring-opacity-50;
}
</style>