import { searchCustomers, searchAdministrators, deleteAdministrator, udapteCustomer, } from "../api";
import Title from "../components/TheTitle.vue";
import Form from "../components/UserListForm.vue";
import Table from "../components/UserListTable.vue";
import { UserAuth } from "../const/userAuthority";
import { Authorize } from "../utils/authorize";
import { UserListType } from "../const/userListType";
import { CryptoUtils } from "../crypto";
import { Message } from "../const/message";
export default {
    name: "UserList",
    data() {
        return {
            searchedCustomers: [],
            searchedAdministrators: [],
            crmUserIdCondition: [],
            storeIdCondition: [],
            customerNoCondition: [],
            registDateStartCondition: [],
            registDateEndCondition: [],
            isAdminList: false,
            isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
            errMsgs: [],
        };
    },
    components: {
        Title,
        Form,
        Table,
    },
    created: async function () {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority !== UserAuth.SYSTEM_ADMIN.code &&
            authority !== UserAuth.STORE_ADMIN.code) {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
        if (Date.now() / 1000 > this.$store.state.tokenExp) {
            if (!(await Authorize.isAuth())) {
                // 認証失敗：ログイン画面へ遷移
                this.$router.push({
                    name: "login",
                    query: { redirect: this.$route.path },
                });
                return false;
            }
        }
    },
    methods: {
        async searchCustomer(customerNo, registDateStart, registDateEnd) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await searchCustomers(customerNo, registDateStart, registDateEnd);
            if (rspBody.result.messages.length) {
                this.searchedCustomers = [];
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 成功した場合、取得したレコードを渡す
                this.searchedCustomers = rspBody.detail;
                // 検索条件を保持させる
                this.customerNoCondition = customerNo;
                this.registDateStartCondition = registDateStart;
                this.registDateEndCondition = registDateEnd;
            }
        },
        async searchAdministrator(crmUserId, storeId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await searchAdministrators(crmUserId, storeId);
            if (rspBody.result.messages.length) {
                this.searchedAdministrators = [];
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 成功した場合、取得したレコードを渡す
                this.searchedAdministrators = rspBody.detail;
                // 検索条件を保持させる
                this.crmUserIdCondition = crmUserId;
                this.storeIdCondition = storeId;
            }
        },
        async udapteCustomer(phoneNum, templateCheckFlg, lockCd, isLokedComment) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 備考欄が空の場合はnullになっているためその場合は空文字に修正する
            if (isLokedComment == null)
                isLokedComment = "";
            // 電話番号の暗号化
            let CryptoPhoneNum = CryptoUtils.encryptoAes(phoneNum);
            const rspBody = await udapteCustomer(CryptoPhoneNum, templateCheckFlg, lockCd, isLokedComment);
            if (rspBody.result.messages.length) {
                this.searchedCustomers = [];
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.searchedCustomers = rspBody.detail;
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // アカウントロック押下後、再度フォームの検索条件でレコードを取得
                const customersRspBody = await searchCustomers(this.customerNoCondition, this.registDateStartCondition, this.registDateEndCondition);
                this.searchedCustomers = customersRspBody.detail;
            }
        },
        async deleteAdministrator(crmUserId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            const rspBody = await deleteAdministrator(crmUserId);
            // 削除押下後、再度フォームの検索条件を取得
            if (rspBody.result.messages.length) {
                this.searchedAdministrators = [];
                // 失敗した場合、エラーメッセージをポップアップにセットして表示
                this.searchedAdministrators = rspBody.detail;
                this.$store.commit("setMessages", rspBody.result.messages);
                this.$store.commit("setCancelBtnHide", true);
                this.$store.commit("setIsModalOpen", true);
            }
            else {
                // 削除押下後、再度フォームの検索条件でレコードを取得
                const administratorsRspBody = await searchAdministrators(this.crmUserIdCondition, this.storeIdCondition);
                this.searchedAdministrators = administratorsRspBody.detail;
            }
        },
        changeUserListType(userTypeCode) {
            if (userTypeCode == UserListType.CUSTOMER.code) {
                this.isAdminList = false;
            }
            else if (userTypeCode == UserListType.ADMIN.code) {
                this.isAdminList = true;
            }
        },
        async csvDownload() {
            const customersRspBody = await searchCustomers(this.customerNoCondition, this.registDateStartCondition, this.registDateEndCondition);
            let customers = customersRspBody.detail;
            if (customers[0] != undefined) {
                let csvContent = "";
                // 現在の検索結果(searchedCustomers)を使用して電話番号のみのCSVを作成する
                customers.forEach((value) => {
                    csvContent += value.phoneNum + "\r\n";
                });
                // オブジェクトURLを生成
                const url = URL.createObjectURL(new Blob([csvContent], { type: "text/plain" }));
                // DL用の疑似aタグを作成
                const link = document.createElement("a");
                document.body.appendChild(link);
                // 現在日時の取得
                const now = new Date();
                // 年月日の型を作成
                let format = "yyyyMMdd";
                // 作成した型に年月日をreplace
                format = format.replace(/yyyy/, now.getFullYear().toString());
                format = format.replace(/MM/, ("0" + (now.getMonth() + 1)).slice(-2));
                format = format.replace(/dd/, ("0" + now.getDate()).slice(-2));
                // ファイル名を設定
                link.download = Message.generateMessage(Message.PUSH_CSV_NAME, [
                    format,
                ]);
                link.href = url;
                // clickイベントが発火、「名前を付けて保存」が開かれる
                link.click();
                // 後処理
                link.remove();
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                }, 1e4);
            }
        },
    },
};
