/** ユーザ区分 */
class CRM {
    /** URI */
    static CRM_URI = "/crm/index.php/login/LoginThrough?uid=";
    /** クエリパラメータ */
    static CRM_QUERY = "&kind=@customer_redirect?customer_id=";
    /**
     * ドメイン、URI、クエリパラメータを合わせてCRMの顧客URLを生成する
     * @param {string} uid ログイン者のユーザID
     * @param {string} customerId 開く対象の顧客ID
     * @returns ユーザ区分の論理名
     */
    static getCrmUrl(uid, customerId) {
        return (process.env.VUE_APP_CRM_URL +
            this.CRM_URI +
            uid +
            this.CRM_QUERY +
            customerId);
    }
}
export { CRM };
