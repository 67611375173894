class Message {
    /** 共通：{0}してもよろしいですか？ */
    static CONFIRM = "{0}してもよろしいですか？";
    /** 共通：{0}が完了しました。 */
    static COMPLETED = "{0}が完了しました。";
    /** 共通：{0}が存在しません */
    static NOT_EXIST = "{0}が存在しません";
    /** 共通：選択されていません */
    static NOT_SELECT = "選択されていません";
    /** 共通：以下の{0}件を{1}します。よろしいですか？ */
    static DELETE_WARN = "以下の{0}件を{1}します。よろしいですか？";
    /** 共通：チェックボックスの値はリセットされますが、よろしいですか？ */
    static TRANSITION_WARN = "チェックボックスの値はリセットされますが、よろしいですか？";
    /** 共通：入力値はクリアされますが、よろしいですか？ */
    static INPUT_VALUE_CLEAR = "入力値はクリアされますが、よろしいですか？";
    /** 共通：{0}対象の{1}を選択してください。 */
    static DELETE_NOT_SELECT = "{0}対象の{1}を選択してください。";
    /** 共通：データが存在しません */
    static MULTI_NOT_RESULT = "データが存在しません";
    /** 共通：データ取得中... */
    static MULTI_NOT_OPTIONS = "データ取得中...";
    /** 共通：一度に{0}できる件数は{1}件までです。 */
    static LIMIT_OVER = "一度に{0}できる件数は {1} 件までです。";
    /** 共通：{0} に {1} は選択できません。 */
    static NON_SELECTABLE = "{0} に {1} は選択できません。";
    /** ユーザ更新：{0}が完了しました。(電話番号を更新した場合、新しい電話番号へ対象ユーザのログインパスワードを送信しています) */
    static USER_UPDATE_COMPLETED = "{0}が完了しました。(電話番号を更新した場合、新しい電話番号へ対象ユーザのログインパスワードを送信しています)";
    /** ユーザ更新：更新が完了しました。(自身の電話番号を更新したため、ログアウトします。新しい電話番号へ送信された仮パスワードを使用して再ログインしてください) */
    static USER_UPDATE_LOGOUT = "更新が完了しました。(自身の電話番号を更新したため、ログアウトします。新しい電話番号へ送信された仮パスワードを使用して再ログインしてください)";
    /** テンプレート登録時、グループID重複時に使用：このグループIDは既に本システムで作成されています。 */
    static ID_EXIST_TEMPLATE = "このグループIDは既に使用されています。";
    /** 管理者登録画面：このCRMユーザIDを持つユーザは既に本システムで作成されています。 */
    static ID_EXIST_CRM_USER = "このCRMユーザIDを持つユーザは既に本システムで作成されています。";
    /** 共通：処理区分 */
    static PROC_TYPE = {
        /** 登録 */
        REGISTER: "登録",
        /** 更新 */
        UPDATE: "更新",
        /** 削除 */
        DELETE: "削除",
        /** アップロード */
        UPLOAD: "アップロード",
    };
    /** 共通：ボタン種類 */
    static BUTTON_TYPE = {
        /** OK */
        OK: "OK",
        /** キャンセル */
        CANCEL: "キャンセル",
        /** 閉じる */
        CLOSE: "閉じる",
    };
    /** パスワード変更 */
    static UPDATE_PASSWORD = {
        /** 8文字以上16文字以内, 半角数字と半角英字をそれぞれ含む記号なしの形式 */
        RULE: [
            "8文字以上16文字以内",
            "半角数字と半角英字をそれぞれ含む記号なしの形式",
        ],
    };
    /** ログインエラーメッセージ */
    static LOGIN_ERROR = {
        /** ログインID存在しない */
        NOT_FOUND_ID: {
            name: "NOT_FOUND_ID",
            message: "このアカウントはOPSに登録されていないため、利用できません。管理者にお問い合わせください。",
        },
        /** 処理失敗 */
        PROCESS_FAILED: {
            name: "PROCESS_FAILED",
            message: "処理中にエラーが発生しました。もう一度処理をやり直してください。\n※このエラーが繰り返し表示される場合はシステム管理者にお問い合わせください。",
        },
    };
    /** ユーザ変更申請マスタ */
    static USERREQ = {
        /** ユーザ変更申請 */
        NAME: "ユーザ変更申請",
        /** ページごとの表示件数 */
        DISPLAY_NUMBER: 50,
    };
    /** 管理ユーザマスタ */
    static USER = {
        /** ユーザ変更申請 */
        NAME: "ユーザ変更申請",
        /** ページごとの表示件数 */
        DISPLAY_NUMBER: 50,
    };
    /** テンプレート */
    static TEMPLATE = {
        /** テンプレート */
        NAME: "テンプレート",
        /** ページごとの表示件数 */
        DISPLAY_NUMBER: 50,
    };
    /** ダウンロードさせるプッシュ通知用CSVのファイル名 */
    static PUSH_CSV_NAME = "{0}_Customer.csv";
    /**
     * メッセージを生成する
     * @param {String} message 置換前の{0}が含まれたメッセージ
     * @param {Array} params 置換する文字列の配列
     * @returns 置換されたメッセージ
     */
    static generateMessage(message, params) {
        let result = message;
        for (let i = 0; i < params.length; i++) {
            // 置換される文字列を生成
            const index = "{" + i + "}";
            result = result.split(index).join(params[i]);
        }
        return result;
    }
    /** 日付エラーメッセージ */
    static DATE = {
        /** CSVアップロード時、全ユーザ配信登録時のチェック */
        UPLOAD_DATE: "配信日は本日以降の日付で登録してください。",
    };
}
export { Message };
