/** プッシュ通知ステータス */
class ReleaseStatus {
    /** 配信待 */
    static OPEN = { code: "1", name: "配信待" };
    /** 配信済 */
    static CLOSED = { code: "2", name: "配信済" };
    /** 配信エラー */
    static ERROR = { code: "3", name: "配信エラー" };
    /** 対応済 */
    static CANCEL = { code: "9", name: "配信取消" };
    /** すべて */
    static ALL = { code: "", name: "すべて" };
    /**
     * ステータスの一覧から配列を作成する
     * @returns ステータスの配列
     */
    static getRequestArray() {
        const array = [this.OPEN, this.CLOSED, this.ERROR, this.CANCEL, this.ALL];
        return array;
    }
}
export { ReleaseStatus };
