import { ValidationType } from "../const/validationType";
import { FileType } from "../const/fileType";
import { Message } from "../const/message";
/** バリデーション */
class Validation {
    /**
     * 必須チェック：入力
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {*} value 値
     * @param {Item} item チェックする項目
     * @param {boolean}} isFile ファイルかどうか(true: ファイルである、false: ファイルでない)
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static requiredInput(item, isFile = false) {
        const errMsgs = [];
        if (!item.value) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            if (isFile) {
                // ファイルの場合は「必須チェック：選択」のメッセージを表示する
                errMsgs.push(Message.generateMessage(ValidationType.REQUIRED_SELECT.message, msgPamams));
            }
            else {
                errMsgs.push(Message.generateMessage(ValidationType.REQUIRED_INPUT.message, msgPamams));
            }
        }
        return errMsgs;
    }
    /**
     * 必須チェック：選択
     * @typedef {object} SelectItem
     * @property {string} key 項目名
     * @property {SelectItemValue} value 値
     * @typedef {object} SelectItemValue
     * @property {string} code コード
     * @property {item} name コードの論理名
     * @param {SelectItem} item チェックする項目({key: '項目名', value: {code: '値', name: 'codeの論理名'}})
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static requiredSelect(item) {
        const errMsgs = [];
        if (!item.code) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.REQUIRED_SELECT.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 属性チェック：ファイル
     * @typedef {object} FileItem
     * @property {string} key 項目名
     * @property {File} value 値
     * @param {FileItem} item チェックする項目
     * @param {FileType} fileType ファイルの種類
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isFile(item, fileType = FileType.CSV) {
        const errMsgs = [];
        const regex = new RegExp(fileType.regex);
        // 正規表現チェック
        if (item.value && !regex.test(item.value.type)) {
            const msgPamams = [item.key, fileType.name];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_FILE.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 属性チェック：数字（0以上の整数）
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {(number|string)} value 値
     * @param {Item} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isInteger(item) {
        const errMsgs = [];
        const regex = new RegExp(/^[0-9]*$/);
        const value = item.value.code ? item.value.code : item.value;
        // 正規表現チェック
        if (value && (!regex.test(value) || Number(value) < 0)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_INTEGER.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 属性チェック：年月（yyyy-MM形式）
     * @typedef {object} StringItem
     * @property {string} key 項目名
     * @property {string} value 値（yyyy-MM形式）
     * @param {StringItem} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isYearMonth(item) {
        const errMsgs = [];
        const regex = new RegExp(/^[0-9]{4}-[0-9]{2}$/);
        const y = item.value.split("-")[0];
        const m = item.value.split("-")[1] - 1;
        const date = new Date(y, m, 1);
        const isNotDate = date.getFullYear() != y || date.getMonth() != m;
        // 正規表現 & 有効な日付形式かどうかチェック
        if (item.value && (!regex.test(item.value) || isNotDate)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_YEAR_MONTH.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 属性チェック：日付（yyyy-MM-dd形式）
     * @typedef {object} StringItem
     * @property {string} key 項目名
     * @property {string} value 値（yyyy-MM-dd形式）
     * @param {StringItem} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isDate(item) {
        const errMsgs = [];
        const regex = new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
        const y = item.value.split("-")[0];
        const m = item.value.split("-")[1] - 1;
        const d = item.value.split("-")[2];
        const date = new Date(y, m, d);
        const isNotDate = date.getFullYear() != y || date.getMonth() != m || date.getDate() != d;
        // 正規表現 & 有効な日付形式かどうかチェック
        if (item.value && (!regex.test(item.value) || isNotDate)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_DATE.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 属性チェック：英数字のみ（記号なし）
     * @typedef {object} StringItem
     * @property {string} key 項目名
     * @property {string} value 値
     * @param {StringItem} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isNotContainSymbols(item) {
        const errMsgs = [];
        const regex = new RegExp(/^[0-9a-zA-Z]+$/);
        // 正規表現チェック
        if (item.value && !regex.test(item.value)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.NOT_CONTAIN_SYMBOLS.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 範囲チェック
     * ※未使用のためコメントアウト
     * 以下を使用する場合は、item.valueとminとmaxがすべて数値である必要があるので、
     * 必要に応じて数値へ型変換して使うこと（※文字列のまま比較しないこと）
     * 参考：javascript 文字列のまま数字を比較すると危険が危ない。
     * https://chaika.hatenablog.com/entry/2016/02/01/153218
     * @typedef {object} NumberItem
     * @property {string} key 項目名
     * @property {number} value 値
     * @param {NumberItem} item チェックする項目
     * @param {number} min 最小値
     * @param {number} max 最大値
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    // static range(item, min, max){
    //   let errMsgs = [];
    //   // TODO: item.valueの数値チェック
    //   // 最小値 <= 入力値 <= 最大値 かどうか
    //   if(item.value && (!min <= item.value || !item.value <= max)){
    //     const msgPamams = [ item.key ];
    //     // エラーメッセージを作成
    //     errMsgs.push(
    //       Message.generateMessage(Type.RANGE.message, msgPamams)
    //     );
    //   }
    //   return errMsgs;
    // }
    /**
     * 桁数チェック：最大
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {(number|string)} value 値
     * @param {Item} item チェックする項目
     * @param {number} max 最大桁
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static digitsMax(item, max) {
        const errMsgs = [];
        // 入力値の桁数 > 最大桁 ではないかどうか
        if (item.value && String(item.value).length > max) {
            const msgPamams = [item.key, max];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.DIGITS_MAX.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 桁数チェック：最小
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {(number|string)} value 値
     * @param {Item} item チェックする項目
     * @param {number} min 最小桁
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static digitsMin(item, min) {
        const errMsgs = [];
        // 入力値の桁数 < 最小桁 ではないかどうか
        if (item.value && String(item.value).length < min) {
            const msgPamams = [item.key, min];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.DIGITS_MIN.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 桁数チェック：固定長
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {(number|string)} value 値
     * @param {Item} item チェックする項目
     * @param {number} length 固定長の桁数
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static fixedLength(item, length) {
        const errMsgs = [];
        const value = item.value.code ? item.value.code : item.value;
        // 入力値の桁数 = 固定長の桁数 かどうか
        if (value && String(value).length !== length) {
            const msgPamams = [item.key, length];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.FIXED_LENGTH.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * メールアドレスチェック：正規表現
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {(number|string)} value 値
     * @param {Item} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isMailFormat(item) {
        const errMsgs = [];
        const regex = new RegExp(/^[a-zA-Z0-9_+-.]+@[a-zA-Z0-9-.]+$/);
        // 正規表現チェック
        if (item.value && !regex.test(item.value)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_MAIL.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 郵便番号チェック：正規表現
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {string} value 値
     * @param {Item} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isZipCdFormat(item) {
        const errMsgs = [];
        const regex = new RegExp(/^[0-9]{3}-[0-9]{4}$/);
        // 正規表現チェック
        if (item.value && !regex.test(item.value)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_ZIP_CD.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 電話番号チェック：正規表現
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {string} value 値
     * @param {Item} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isPhoneNumFormat(item) {
        const errMsgs = [];
        const regex = new RegExp(/^0[0-9]{10}$/);
        // 正規表現チェック
        if (item.value && !regex.test(item.value)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_PHONE_NUM.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * パスワードチェック：正規表現
     * @typedef {object} Item
     * @property {string} key 項目名
     * @property {string} value 値
     * @param {Item} item チェックする項目
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isPassword(item) {
        const errMsgs = [];
        const regex = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9].+$/);
        // 正規表現チェック
        if (item.value && !regex.test(item.value)) {
            const msgPamams = [item.key];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.IS_PASSWORD.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * ファイルサイズチェック：最大(MB)
     * @typedef {object} FileItem
     * @property {string} key 項目名
     * @property {File} value 値
     * @param {FileItem} item チェックする項目
     * @param {number} maxMb 最大ファイルサイズ(MB)
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static fileSizeMax(item, maxMb) {
        const errMsgs = [];
        const sizeLimit = 1024 * 1024 * maxMb;
        const value = item.value;
        // ファイルサイズが指定されたサイズ以下かどうか
        if (value && new Blob([value]).size && new Blob([value]).size > sizeLimit) {
            const msgPamams = [item.key, maxMb];
            // エラーメッセージを作成
            errMsgs.push(Message.generateMessage(ValidationType.FILE_SIZE_MAX.message, msgPamams));
        }
        return errMsgs;
    }
    /**
     * 現在日付(9:00)をDate型で取得
     * @returns Date型の現在日付(9:00)
     */
    static getNowDate() {
        // 現在日時の取得
        const now = new Date();
        // 年月日の型を作成
        let format = "yyyy-MM-dd";
        // 作成した型に年月日をreplace
        // TODO: 要動作確認
        format = format.replace(/yyyy/, now.getFullYear().toString());
        format = format.replace(/MM/, ("0" + (now.getMonth() + 1)).slice(-2));
        format = format.replace(/dd/, ("0" + now.getDate()).slice(-2));
        return new Date(format);
    }
    /**
     * ライセンス登録：日付相関チェック
     * @typedef {object} StringItem
     * @property {string} value 値
     * @param {StringItem} DeliveryDate 配信日付(value：yyyy-mm-dd形式)
     * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
     */
    static isOkReleaseDate(DeliveryDate) {
        const errMsgs = [];
        // 日付文字列をDate型に変換
        const releaseDate = new Date(DeliveryDate.value);
        const sysDate = this.getNowDate();
        if (releaseDate <= sysDate) {
            // 配信日を現在日時より前に指定した場合エラーメッセージを作成
            errMsgs.push(Message.DATE.UPLOAD_DATE);
        }
        return errMsgs;
    }
}
export { Validation };
