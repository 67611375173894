import { getAdministrator, updateAdministrator, deleteAdministrator, } from "../api";
import Title from "../components/TheTitle.vue";
import { Message } from "../const/message";
import ErrMsgCard from "../components/ErrorMssageCard.vue";
import { UserAuth } from "../const/userAuthority";
import { Authorize } from "../utils/authorize";
import { Validation } from "../utils/validation";
import { StoreCd } from "../const/storeCd";
import { CryptoUtils } from "../crypto";
import { Cookie } from "../utils/cookie";
export default {
    name: "UserManageUpdate",
    data() {
        return {
            auth: "manager",
            crmUserId: {
                key: "CRMユーザID",
                value: this.$route.params.crmUserId,
            },
            userName: { key: "氏名", value: "" },
            storeId: { key: "支店コード", value: "" },
            phoneNum: { key: "電話番号", value: "" },
            authType: { key: "ユーザ区分", value: "" },
            systemAdmin: UserAuth.SYSTEM_ADMIN.code,
            storeAdmin: UserAuth.STORE_ADMIN.code,
            adminStoreCd: StoreCd.SYSTEM_ADMIN.code,
            errMsgs: [],
            isUnsent: true,
        };
    },
    components: {
        Title,
        ErrMsgCard,
    },
    async created() {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority === UserAuth.SYSTEM_ADMIN.code) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 更新対象の管理ユーザ情報を取得
            getAdministrator((rspBody) => {
                if (rspBody.result.code === 0) {
                    let administrator = rspBody.detail;
                    this.userName.value = administrator.userName;
                    this.storeId.value = administrator.storeId;
                    this.phoneNum.value = administrator.phoneNum;
                    this.authType.value = administrator.authType;
                }
                else {
                    // ユーザが存在しない場合、ポップアップを表示
                    this.$store.commit("setMessages", [
                        Message.generateMessage(Message.NOT_EXIST, [Message.USER.NAME]),
                    ]);
                    this.$store.commit("setOkAction", () => {
                        this.toUserList();
                    });
                    this.$store.commit("setCancelBtnHide", true);
                    this.$store.commit("setIsModalOpen", true);
                    return false;
                }
            }, this.crmUserId.value);
        }
        else {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
    },
    methods: {
        async update() {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 本部管理者の場合、店舗IDは固定にする(69)
            if (this.authType.value === UserAuth.SYSTEM_ADMIN.code) {
                this.storeId.value = this.adminStoreCd;
            }
            this.isUnsent = false;
            // バリデーション定義作成;
            let validationArray = [];
            // バリデーションチェック実施
            this.errMsgs = validationArray.concat(Validation.requiredInput(this.userName), Validation.digitsMax(this.userName, 50), 
            // 支店コードの必須チェックはユーザ区分によって判断する
            this.authType.value === UserAuth.STORE_ADMIN.code
                ? Validation.requiredInput(this.storeId)
                : [], Validation.digitsMax(this.storeId, 4), Validation.requiredInput(this.phoneNum), Validation.digitsMax(this.phoneNum, 11), Validation.isPhoneNumFormat(this.phoneNum));
            if (this.errMsgs.length) {
                // エラーメッセージがある場合は処理を中断
                return false;
            }
            // 更新確認ポップアップを表示
            this.$store.commit("setMessages", [
                Message.generateMessage(Message.CONFIRM, [Message.PROC_TYPE.UPDATE]),
            ]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", async () => {
                // ユーザ名、電話番号の暗号化
                let userName = CryptoUtils.encryptoAes(this.userName.value);
                let phoneNum = CryptoUtils.encryptoAes(this.phoneNum.value);
                // 管理ユーザ更新API呼び出し
                updateAdministrator((rspBody) => {
                    if (rspBody.result.code === 0) {
                        let flg = rspBody.detail;
                        if (flg) {
                            // 自身の電話番号を変更した場合はログアウト
                            this.$router.push({
                                name: "login",
                            });
                            // storeに保持している情報を初期化
                            localStorage.removeItem("ops-front-app");
                            // localStrageに保持している情報を初期化
                            localStorage.removeItem("iToken");
                            localStorage.removeItem("aToken");
                            // cookieからtokenを削除
                            Cookie.deleteToken();
                            // 更新成功ポップアップ表示
                            this.$store.commit("setMessages", [
                                Message.USER_UPDATE_LOGOUT,
                            ]);
                            this.$store.commit("setIsModalInfo", true);
                            this.$store.commit("setCancelBtnHide", true);
                            this.$store.commit("setIsModalOpen", true);
                        }
                        else {
                            // 通常の成功時はユーザ一覧画面へ遷移
                            this.toUserList();
                            // 更新成功ポップアップ表示
                            this.$store.commit("setMessages", [
                                Message.generateMessage(Message.USER_UPDATE_COMPLETED, [
                                    Message.PROC_TYPE.UPDATE,
                                ]),
                            ]);
                            this.$store.commit("setIsModalInfo", true);
                            this.$store.commit("setCancelBtnHide", true);
                            this.$store.commit("setIsModalOpen", true);
                        }
                    }
                    else {
                        // 失敗した場合、エラー内容を表示
                        this.errMsgs = rspBody.result.messages;
                    }
                }, this.crmUserId.value, userName, this.storeId.value, phoneNum, this.authType.value);
            });
        },
        async remove(crmUserId) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            this.isUnsent = false;
            // 削除確認ポップアップ表示
            this.$store.commit("setMessages", [
                Message.generateMessage(Message.CONFIRM, [Message.PROC_TYPE.DELETE]),
            ]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", async () => {
                const rspBody = await deleteAdministrator(crmUserId);
                //成功した場合、ユーザ一覧画面へ遷移
                this.toUserList();
                if (rspBody.result.messages.length) {
                    // 失敗した場合、エラーメッセージをポップアップにセットして表示
                    this.$store.commit("setMessages", rspBody.result.messages);
                    this.$store.commit("setCancelBtnHide", true);
                    this.$store.commit("setIsModalOpen", true);
                    return false;
                }
            });
        },
        toUserList() {
            this.$router.push({
                name: "user",
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isUnsent && to.name != "maintenance") {
            this.$store.commit("setMessages", [Message.INPUT_VALUE_CLEAR]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", function () {
                next();
            });
            this.$store.commit("setCancelAction", function () {
                next(false);
            });
        }
        else {
            next();
        }
    },
};
