/** 表示ユーザ区分 */
class UserListType {
    /** 利用ユーザ */
    static CUSTOMER = { code: "0", name: "利用者" };
    /** 管理ユーザ */
    static ADMIN = { code: "1", name: "管理者" };
    /**
     * ユーザ区分を配列で取得
     * @returns ユーザ区分の配列
     */
    static getList() {
        const array = [];
        for (const key in this) {
            array.push(this[key]);
        }
        return array;
    }
}
export { UserListType };
