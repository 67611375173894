import Title from "../components/TheTitle.vue";
import { Authorize } from "../utils/authorize";
import { UserAuth } from "../const/userAuthority";
import { getTemplate, updateTemplate } from "../api";
import { Message } from "../const/message";
import { Validation } from "../utils/validation";
import ErrMsgCard from "../components/ErrorMssageCard.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import BlotFormatter from "quill-blot-formatter";
export default {
    name: "TemplateUpdate",
    data() {
        return {
            templateId: { key: "グループID", value: "" },
            division: { code: "2", setValue1: "グランフォト" },
            releaseTime: { key: "配信時間", value: "" },
            crmGetFlg: { key: "パラメータ取得フラグ", value: false },
            title: { key: "タイトル", value: "" },
            sub: { key: "プッシュ通知内容", value: "" },
            body: { key: "お知らせ内容", value: "" },
            errMsgs: [],
            searchedDivisions: [],
            isUnsent: true,
            toolbar: [
                "bold",
                "italic",
                "underline",
                { color: [] },
                "image",
                "link",
            ],
            modules: {
                name: "modules/blotFormatter",
                module: BlotFormatter,
                options: {
                /* options */
                },
            },
        };
    },
    components: {
        Title,
        ErrMsgCard,
        QuillEditor,
    },
    async created() {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority !== UserAuth.STORE_ADMIN.code &&
            authority !== UserAuth.SYSTEM_ADMIN.code) {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
        if (Date.now() / 1000 > this.$store.state.tokenExp) {
            if (!(await Authorize.isAuth())) {
                // 認証失敗：ログイン画面へ遷移
                this.$router.push({
                    name: "login",
                    query: { redirect: this.$route.path },
                });
                return false;
            }
        }
        // // 事業部一覧全件取得
        // const divisionsRspBody = await getDivisions();
        // this.searchedDivisions = divisionsRspBody.detail;
        // 更新対象のテンプレート本文を取得
        const rspBody = await getTemplate(this.$route.query.templateId);
        if (rspBody.result.code === 0) {
            this.templateId.value = this.$route.query.templateId;
            this.division.setValue1 = this.$route.query.division;
            this.releaseTime.value = this.$route.query.releaseTime;
            this.title.value = this.$route.query.title;
            this.sub.value = this.$route.query.sub;
            this.$refs.myEditor.setHTML(rspBody.detail.body);
            this.crmGetFlg.value = this.$route.query.crmGetFlg;
        }
        else {
            // テンプレートが存在しない場合、ポップアップを表示
            this.$store.commit("setMessages", [
                Message.generateMessage(Message.NOT_EXIST, [Message.TEMPLATE.NAME]),
            ]);
            this.$store.commit("setOkAction", () => {
                this.$router.push({
                    name: "template",
                });
            });
            this.$store.commit("setCancelBtnHide", true);
            this.$store.commit("setIsModalOpen", true);
            return false;
        }
    },
    methods: {
        async update() {
            this.body.value = this.$refs.myEditor.getHTML();
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // バリデーション定義作成;
            let validationArray = [];
            // バリデーションチェック実施
            this.errMsgs = validationArray.concat(Validation.requiredInput(this.templateId), Validation.requiredInput(this.releaseTime), Validation.digitsMax(this.releaseTime, 5), Validation.requiredInput(this.title), Validation.requiredInput(this.sub), Validation.requiredInput(this.body), Validation.fileSizeMax(this.body, 6));
            if (this.errMsgs.length) {
                // エラーメッセージがある場合は処理を中断
                return false;
            }
            // 更新確認ポップアップを表示
            this.$store.commit("setMessages", [
                Message.generateMessage(Message.CONFIRM, [Message.PROC_TYPE.UPDATE]),
            ]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", async () => {
                // プッシュ通知テンプレート更新API呼び出し
                updateTemplate((rspBody) => {
                    if (rspBody.result.code === 0) {
                        // 成功した場合ユーザ一覧画面へ遷移
                        this.toTemplateList();
                        // 更新成功ポップアップ表示;
                        this.$store.commit("setMessages", [
                            Message.generateMessage(Message.COMPLETED, [
                                Message.PROC_TYPE.UPDATE,
                            ]),
                        ]);
                        this.$store.commit("setIsModalInfo", true);
                        this.$store.commit("setCancelBtnHide", true);
                        this.$store.commit("setIsModalOpen", true);
                    }
                    else {
                        // 失敗した場合、エラー内容を表示
                        this.errMsgs = rspBody.result.messages;
                    }
                }, this.templateId.value, this.title.value, this.sub.value, this.body.value, this.releaseTime.value, this.crmGetFlg.value);
                this.isUnsent = false;
            });
        },
        toTemplateList() {
            this.$router.push({
                name: "template",
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isUnsent && to.name != "maintenance") {
            this.$store.commit("setMessages", [Message.INPUT_VALUE_CLEAR]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", function () {
                next();
            });
            this.$store.commit("setCancelAction", function () {
                next(false);
            });
        }
        else {
            next();
        }
    },
};
