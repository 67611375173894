<template>
  <div class="abusolute-center bg-white shadow-lg w-full sm:w-max rounded-lg">
    <div class="py-8 px-5 sm:px-12" id="app">
      <div class="flex justify-center p-4 border-red-700 border-b-4">
        <img class="w-16" alt="logo" src="../assets/logo.png" />
      </div>

      <div class="py-8 px-5 sm:px-12" id="app">
        <div class="max-w-lg">
          <ErrMsgCard :errMsgs="errMsgs" process="二段階認証" />
        </div>
        <form
          v-on:submit.prevent="inputInfo"
          class="grid grid-cols-1 gap-6 text-gray-700"
        >
          <label class="block">
            <span class="text-gray-700">{{ authCode.key }}</span>
            <input
              type="text"
              :placeholder="authCode.key + 'を入力'"
              v-model="authCode.value"
              maxlength="50"
              class="w-full"
            />
          </label>

          <label class="block">
            <div class="flex justify-center">
              <button
                button
                v-on:click="submit"
                name="button"
                class="btn btn-red w-full"
              >
                認証
              </button>
            </div>
          </label>

          <label class="block">
            <div class="flex justify-first">
              <router-link to="/">再度認証コードを送信する </router-link>
            </div>
          </label>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { twoFactAuthentication } from "../api";
  import ErrMsgCard from "../components/ErrorMssageCard.vue";
  import { Message } from "../const/message";
  import { Validation } from "../utils/validation";
  import { Cookie } from "../utils/cookie";
  import { v4 as uuidv4 } from "uuid";

  export default {
    name: "TwoFactorAuth",
    components: {
      ErrMsgCard,
    },
    data() {
      return {
        authCode: { key: "認証コード", value: "" },
        session: "",
        authority: "",
        crmUserId: "",
        errMsgs: [],
      };
    },
    async created() {
      if (this.$route.query.errMsg) {
        const queryErrMsg = this.$route.query.errMsg;
        if (Array.isArray(queryErrMsg)) {
          // エラーメッセージが配列の場合、ループ
          queryErrMsg.forEach((msgCd) => {
            // コードを日本語のメッセージに変換してerrMsgsセット
            const msg = Message.LOGIN_ERROR[msgCd].message;
            this.errMsgs.push(msg);
          });
        } else {
          // エラーメッセージが配列ではない場合
          // コードを日本語のメッセージに変換してerrMsgsセット
          const msg = Message.LOGIN_ERROR[queryErrMsg].message;
          this.errMsgs.push(msg);
        }
      }

      // ログイン済みの場合はトップページにリダイレクト
      if (localStorage.getItem("iToken")) {
        this.$router.push({ name: "user" });
      }
    },
    methods: {
      submit() {
        // バリデーション定義作成
        let validationArray = [];
        // バリデーションチェック実施
        this.errMsgs = validationArray.concat(
          Validation.requiredInput(this.authCode)
        );

        if (this.errMsgs.length) {
          // エラーメッセージがある場合は処理を中断
          return false;
        }
        // UUIDを取得
        var clientId = Cookie.getClientId();
        if (!clientId) {
          clientId = uuidv4();
        }
        // cookieにクライアントIDをセット
        Cookie.setClientId(clientId);
        // ログインAPI呼び出し
        twoFactAuthentication(
          async (rspBody) => {
            if (rspBody.result.code === 9) {
              // 処理結果が失敗の場合
              if (rspBody.result.messages.length) {
                // エラーメッセージを表示
                this.errMsgs = rspBody.result.messages;
              }
            } else {
              // 処理結果が成功の場合
              let user = rspBody.detail;
              // IDトークンとアクセストークンを保持
              localStorage.setItem("iToken", user.idToken);
              localStorage.setItem("aToken", user.accessToken);
              // cookieにIDトークンをセット
              Cookie.setToken(user.idToken);
              // リフレッシュトークンとユーザ情報を保持
              this.$store.commit("setRToken", user.refreshToken);
              this.$store.commit("setTokenExp", user.tokenExp);
              this.$store.commit("setStoreCd", user.storeId);
              this.$store.commit("setAuthority", user.userType);
              this.$store.commit("setDisplayName", user.displayName);
              this.$store.commit("setLoginId", user.crmUserId);
              if (user.isNotChangedPassword === "1") {
                // パスワード未変更の場合はパスワード変更画面へ遷移
                this.$router.push({
                  name: "password-change",
                  params: {
                    isReturn: 0,
                  },
                });
              } else {
                // 上記以外の場合はユーザ一覧ページへ遷移
                this.$router.push({ name: "user" });
              }
            }
          },
          this.authCode.value,
          this.$route.query.session,
          this.$route.query.authority,
          this.$route.query.crmUserId
        );
      },
    },
  };
</script>

<style scoped>
  .abusolute-center {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    width: 640px;
  }
  input {
    @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:bg-white bg-gray-50;
  }
  a {
    @apply text-indigo-800 underline;
  }
</style>
