import { UserUpdateReq } from "../const/userUpdateReq";
export default {
    name: "TemplateListForm",
    props: {
        requestsList: {
            type: Array,
            default: () => [],
        },
        handleSearch: {
            type: Function,
            required: true,
        },
        isSysAdmin: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            customerNo: { name: "お客様番号", value: "" },
            storeCd: { name: "支店コード", value: "" },
            statusList: UserUpdateReq.getRequestArray(),
            status: { name: "すべて", code: "9" },
        };
    },
    methods: {
        search() {
            const customerNo = this.customerNo.value ?? "";
            let storeCd;
            if (!this.isSysAdmin) {
                // 店舗管理者の場合は自身の店舗のみ検索対象とする
                storeCd = this.$store.state.storeCd;
            }
            else {
                storeCd = this.storeCd.value ?? "";
            }
            this.handleSearch(storeCd, customerNo, this.status.code);
        },
        clear() {
            Object.assign(this.$data, this.$options.data());
        },
    },
};
