import * as crypto from "crypto";
import publicPem from "./keys/publicPem";
import commonKey from "./keys/commonKey";
const ALGORITHM = "aes-128-cbc";
/**
 * 文字列暗号化／復号化クラス
 * RSA暗号化方式はパスワード等の個人情報に使用
 * AES暗号化方式はトークンに使用
 */
class CryptoUtils {
    /**
     * 公開鍵暗号方式での暗号化
     * @param {string} plaintext 平文
     * @returns 暗号化文字列
     */
    static encryptoRsa(plaintext) {
        // 暗号化
        const encrypt = crypto.publicEncrypt({ key: publicPem, padding: crypto.constants.RSA_PKCS1_OAEP_PADDING }, Buffer.from(plaintext, "utf-8"));
        // 暗号文返却
        return encrypt.toString("base64");
    }
    /**
     * 共通鍵暗号方式での暗号化
     * @param {string} plaintext 平文
     * @returns 暗号化文字列
     */
    static encryptoAes(plaintext) {
        // 16byteのランダム値を生成してIVとする
        const iv = crypto.randomBytes(16);
        // 暗号器作成
        const cipher = crypto.createCipheriv(ALGORITHM, Buffer.from(commonKey, "utf-8"), iv);
        // 平文を暗号化(bufferで暗号化すると文字数によってはエラーになるため、先に16進数で暗号化する)
        let cipBase64 = cipher.update(plaintext, "utf-8", "base64");
        cipBase64 += cipher.final("base64");
        const cipBuf = Buffer.from(cipBase64, "base64");
        // 先頭にivを付与し、16進数をbase64(文字列)にして返す
        return Buffer.concat([iv, cipBuf]).toString("base64");
    }
    /**
     * 共通鍵暗号方式での復号化
     * @param {string} cipher 暗号化文字列
     * @returns 平文
     */
    static decryptoAes(cipher) {
        // 受け取った暗号化文字列をバイナリに変換
        const buff = Buffer.from(cipher, "base64");
        // iv値である、先頭16byteを取り出す
        const iv = buff.slice(0, 16);
        // iv値以降の、暗号化データを取り出す
        const encData = buff.slice(16);
        // 復号器作成
        const decipher = crypto.createDecipheriv(ALGORITHM, commonKey, iv);
        const decipBase64 = encData.toString("base64");
        // 暗号化データを復号化
        let deciphered = decipher.update(decipBase64, "base64", "utf-8");
        // バイナリを文字列に戻す
        deciphered += decipher.final("utf-8");
        return deciphered;
    }
    /**
     * 公開鍵で暗号化した共通鍵を返却する
     * @returns 公開鍵で暗号化した共通鍵
     */
    static commonKeyEncryptoRsa() {
        return CryptoUtils.encryptoRsa(commonKey);
    }
}
export { CryptoUtils };
