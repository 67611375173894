import Title from "../components/TheTitle.vue";
import { getDivisions, searchTemplates, uploadPushNotificationAll, } from "../api";
import { UserAuth } from "../const/userAuthority";
import { Message } from "../const/message";
import { Authorize } from "../utils/authorize";
import { Validation } from "../utils/validation";
import ErrMsgCard from "../components/ErrorMssageCard.vue";
export default {
    name: "PushAllCustomer",
    data() {
        return {
            releaseDate: { key: "配信日", value: "" },
            division: { code: "2", setValue1: "グランフォト" },
            templateId: { key: "グループID", code: "" },
            searchedDivisions: [],
            searchedTemplateTitle: [],
            templateTitleList: [],
            errMsgs: [],
            isUnsent: true,
        };
    },
    components: {
        Title,
        ErrMsgCard,
    },
    created: async function () {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority === UserAuth.SYSTEM_ADMIN.code) {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // 画面表示時、事業部一覧全件取得
            const divisionsRspBody = await getDivisions();
            this.searchedDivisions = divisionsRspBody.detail;
            // 画面表示時、テンプレートのグループID一覧を全件取得
            const templatesTitleRspBody = await searchTemplates(null, null, "0");
            this.searchedTemplateTitle = templatesTitleRspBody.detail;
            this.searchedTemplateTitle.forEach((value) => {
                // 初期値(グランフォト)の値のみを出力
                if (value.divisionCode === this.division.code) {
                    this.templateTitleList.push(value);
                }
            });
        }
        else {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
    },
    methods: {
        async register() {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            this.templateId = this.templateId
                ? this.templateId
                : { key: "グループID", code: "" };
            // バリデーション定義作成;
            let validationArray = [];
            // バリデーションチェック実施
            this.errMsgs = validationArray.concat(Validation.requiredSelect(this.templateId), Validation.requiredInput(this.releaseDate), Validation.isOkReleaseDate(this.releaseDate), Validation.digitsMax(this.releaseDate, 10));
            if (this.errMsgs.length) {
                // エラーメッセージがある場合は処理を中断
                return false;
            }
            // 登録確認ポップアップを表示
            this.$store.commit("setMessages", [
                Message.generateMessage(Message.CONFIRM, [
                    Message.PROC_TYPE.REGISTER,
                ]),
            ]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", async () => {
                // 配信日付の正規化(yyyy-mm-dd⇒yyyy/mm/dd)
                let regexp = /(\d{4})-(\d{2})-(\d{2})/;
                this.releaseDate.value = this.releaseDate.value.replace(regexp, "$1/$2/$3");
                // 全ユーザ向けプッシュ通知登録API呼び出し
                uploadPushNotificationAll((rspBody) => {
                    if (rspBody.result.code === 0) {
                        // 成功した場合ユーザ一覧画面へ遷移
                        this.$router.push({ name: "release-status" });
                        // 登録成功ポップアップ表示;
                        this.$store.commit("setMessages", [
                            Message.generateMessage(Message.COMPLETED, [
                                Message.PROC_TYPE.REGISTER,
                            ]),
                        ]);
                        this.$store.commit("setIsModalInfo", true);
                        this.$store.commit("setCancelBtnHide", true);
                        this.$store.commit("setIsModalOpen", true);
                    }
                    else {
                        // 失敗した場合、エラー内容を表示
                        this.errMsgs = rspBody.result.messages;
                    }
                }, this.templateId.code, this.releaseDate.value, this.division.code);
                this.isUnsent = false;
            });
        },
        filter() {
            this.templateId = { key: "グループID", code: "" };
            this.templateTitleList = [];
            this.searchedTemplateTitle.forEach((value) => {
                if (value.divisionCode === this.division.code) {
                    this.templateTitleList.push(value);
                }
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isUnsent && to.name != "maintenance") {
            this.$store.commit("setMessages", [Message.INPUT_VALUE_CLEAR]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", function () {
                next();
            });
            this.$store.commit("setCancelAction", function () {
                next(false);
            });
        }
        else {
            next();
        }
    },
};
