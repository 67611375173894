export default {
    name: "TemplateListForm",
    data() {
        return {
            activateSubmit: "",
            value: { name: "", code: "" },
            division: { code: "", setValue1: "すべて" },
        };
    },
    props: {
        requestsList: {
            type: Array,
            default: () => [],
        },
        searchedDivisions: { type: Array, default: () => [] },
        templateTitleList: { type: Array, default: () => [] },
        handleSearch: {
            type: Function,
            required: true,
        },
        templateFilter: {
            type: Function,
            required: true,
        },
    },
    methods: {
        search() {
            let templateId = this.value ? this.value.code : "";
            this.handleSearch(templateId, this.division.code);
        },
        filter() {
            this.value = { name: "", code: "" };
            this.templateFilter(this.division.code);
        },
        clear() {
            Object.assign(this.$data, this.$options.data());
            this.templateFilter(this.division.code);
        },
    },
};
