import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import TwoFactorAuth from "../views/TwoFactorAuth.vue";
import PasswordChange from "../views/PasswordChange.vue";
import PasswordReset from "../views/PasswordReset.vue";
import TemplateRegister from "../views/TemplateRegister.vue";
import Templatelist from "../views/TemplateList.vue";
import UpdateRequest from "../views/UpdateRequest.vue";
import UserList from "../views/UserList.vue";
import UserRegister from "../views/UserRegister.vue";
import UserUpdate from "../views/UserUpdate.vue";
import ReleaseStatus from "../views/ReleaseStatus.vue";
import CsvImport from "../views/CsvImport.vue";
import PushAllCustomer from "../views/PushAllCustomer.vue";
import Maintenance from "../views/errors/Maintenance.vue";
import NotFound from "../views/errors/404.vue";
import TemplateUpdate from "../views/TemplateUpdate.vue";
const routes = [
    {
        path: "/",
        name: "login",
        component: Login,
    },
    {
        path: "/two-Factor-Auth",
        name: "two-Factor-Auth",
        component: TwoFactorAuth,
    },
    {
        path: "/password-change/:isReturn",
        name: "password-change",
        component: PasswordChange,
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: PasswordReset,
    },
    {
        path: "/update-request",
        name: "updateRequest",
        component: UpdateRequest,
    },
    {
        path: "/user",
        name: "user",
        component: UserList,
    },
    {
        path: "/user/register",
        name: "user-register",
        component: UserRegister,
    },
    {
        path: "/user/update/:crmUserId",
        name: "user-update",
        component: UserUpdate,
    },
    {
        path: "/template",
        name: "template",
        component: Templatelist,
    },
    {
        path: "/template/register",
        name: "template-register",
        component: TemplateRegister,
    },
    {
        path: "/template/update:templateId",
        name: "template-update",
        component: TemplateUpdate,
    },
    {
        path: "/release-status",
        name: "release-status",
        component: ReleaseStatus,
    },
    {
        path: "/release-status/csv-import",
        name: "csv-import",
        component: CsvImport,
    },
    {
        path: "/release-status/push-all-customer",
        name: "push-all-customer",
        component: PushAllCustomer,
    },
    {
        path: "/maintenance",
        name: "maintenance",
        component: Maintenance,
    },
    {
        path: "/:catchAll(.*)",
        name: "not-found",
        component: NotFound,
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export { router };
