import Title from "../components/TheTitle.vue";
import { updatePassword } from "../api";
import ErrMsgCard from "../components/ErrorMssageCard.vue";
import { Message } from "../const/message";
import { Validation } from "../utils/validation";
import { CryptoUtils } from "../crypto";
import { UserAuth } from "../const/userAuthority";
import { Authorize } from "../utils/authorize";
export default {
    name: "PasswordChandge",
    components: {
        Title,
        ErrMsgCard,
    },
    data() {
        return {
            isReturn: this.$route.params.isReturn,
            loginId: this.$store.state.loginId,
            oldPassword: { key: "現在のパスワード", value: "" },
            newPassword: { key: "新しいパスワード", value: "" },
            newPasswordConfirm: { key: "新しいパスワード（確認）", value: "" },
            isOldPassVisible: false,
            isNewPassVisible: false,
            isNewPassConfirmVisible: false,
            passwordRuleMsgs: Message.UPDATE_PASSWORD.RULE,
            errMsgs: [],
            isUnsent: true,
        };
    },
    created: async function () {
        // 権限チェック
        const authority = this.$store.state.authority;
        if (authority !== UserAuth.STORE_ADMIN.code &&
            authority !== UserAuth.SYSTEM_ADMIN.code) {
            // エラーページに遷移
            this.$router.push({
                name: "not-found",
                params: { catchAll: "not-found" },
            });
            // 処理強制終了
            return false;
        }
        if (Date.now() / 1000 > this.$store.state.tokenExp) {
            if (!(await Authorize.isAuth())) {
                // 認証失敗：ログイン画面へ遷移
                this.$router.push({
                    name: "login",
                    query: { redirect: this.$route.path },
                });
                return false;
            }
        }
    },
    methods: {
        async update() {
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            // パスワードをマスキング
            this.isOldPassVisible = false;
            this.isNewPassVisible = false;
            this.isNewPassConfirmVisible = false;
            // バリデーション定義作成
            let validationArray = [];
            // バリデーションチェック実施
            this.errMsgs = validationArray.concat(this.isReturn == 1 ? Validation.requiredInput(this.oldPassword) : [], this.isReturn == 1
                ? Validation.isNotContainSymbols(this.oldPassword)
                : [], this.isReturn == 1 ? Validation.digitsMin(this.oldPassword, 8) : [], Validation.requiredInput(this.newPassword), Validation.isPassword(this.newPassword), Validation.digitsMin(this.newPassword, 8), Validation.digitsMax(this.newPassword, 16), Validation.requiredInput(this.newPasswordConfirm), Validation.isPassword(this.newPasswordConfirm), Validation.digitsMin(this.newPasswordConfirm, 8), Validation.digitsMax(this.newPasswordConfirm, 16));
            // エラーメッセージがある場合は処理を中断
            if (this.errMsgs.length)
                return false;
            // パスワードの暗号化
            let encryptoOldPass = CryptoUtils.encryptoAes(this.oldPassword.value);
            let encryptoNewPass = CryptoUtils.encryptoAes(this.newPassword.value);
            let encryptoNewConfirmPass = CryptoUtils.encryptoAes(this.newPasswordConfirm.value);
            if (Date.now() / 1000 > this.$store.state.tokenExp) {
                if (!(await Authorize.isAuth())) {
                    // 認証失敗：ログイン画面へ遷移
                    this.$router.push({
                        name: "login",
                        query: { redirect: this.$route.path },
                    });
                    return false;
                }
            }
            updatePassword((rspBody) => {
                if (rspBody.result.code === 0) {
                    this.isUnsent = false;
                    // ルートページへ遷移
                    this.$router.push({ name: "user" });
                    // 更新成功ポップアップ表示
                    this.$store.commit("setMessages", [
                        Message.generateMessage(Message.COMPLETED, [
                            Message.PROC_TYPE.UPDATE,
                        ]),
                    ]);
                    this.$store.commit("setIsModalInfo", true);
                    this.$store.commit("setCancelBtnHide", true);
                    this.$store.commit("setIsModalOpen", true);
                }
                else {
                    // 失敗した場合、エラー内容を表示
                    this.errMsgs = rspBody.result.messages;
                }
            }, encryptoOldPass, encryptoNewPass, encryptoNewConfirmPass);
        },
        oldPassVisibleToggle() {
            this.isOldPassVisible = !this.isOldPassVisible;
        },
        newPassVisibleToggle() {
            this.isNewPassVisible = !this.isNewPassVisible;
        },
        newPassConfirmVisibleToggle() {
            this.isNewPassConfirmVisible = !this.isNewPassConfirmVisible;
        },
        getPassInputType(isPassVisible) {
            return isPassVisible ? "text" : "password";
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isUnsent && to.name != "maintenance") {
            this.$store.commit("setMessages", [Message.INPUT_VALUE_CLEAR]);
            this.$store.commit("setCancelBtnMsg", Message.BUTTON_TYPE.CANCEL);
            this.$store.commit("setIsModalOpen", true);
            this.$store.commit("setOkAction", function () {
                next();
            });
            this.$store.commit("setCancelAction", function () {
                next(false);
            });
        }
        else {
            next();
        }
    },
};
