import { ReleaseStatus } from "../const/releaseStatus";
export default {
    name: "TemplateListForm",
    data() {
        return {
            releaseDateStart: { name: "配信開始範囲", value: "" },
            releaseDateEnd: { name: "配信終了範囲", value: "" },
            templateId: { name: "グループID", value: "" },
            releaseStatusList: ReleaseStatus.getRequestArray(),
            value: { name: "", code: "" },
            status: { code: "", name: "すべて" },
            division: { code: "2", setValue1: "グランフォト" },
        };
    },
    props: {
        requestsList: {
            type: Array,
            default: () => [],
        },
        templateTitleList: { type: Array, default: () => [] },
        searchedDivisions: { type: Array, default: () => [] },
        handleSearch: {
            type: Function,
            required: true,
        },
        templateFilter: {
            type: Function,
            required: true,
        },
        isSysAdmin: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: {
        search() {
            let releaseDateStart = this.releaseDateStart.value ?? "";
            let releaseDateEnd = this.releaseDateEnd.value ?? "";
            let templateId = this.value ? this.value.code : "";
            this.handleSearch(templateId, releaseDateStart, releaseDateEnd, this.status.code, this.division.code);
        },
        filter() {
            this.value = { name: "", code: "" };
            this.templateFilter(this.division.code);
        },
        clear() {
            Object.assign(this.$data, this.$options.data());
            this.templateFilter(this.division.code);
        },
    },
};
