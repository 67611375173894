export default {
    props: {
        errMsgs: {
            type: Array,
            default: () => [],
        },
        process: {
            type: String,
            required: true,
        },
    },
    watch: {
        errMsgs: function (errMsg) {
            if (errMsg.length) {
                // エラーメッセージがある場合は一番上までスクロール
                scrollTo(0, 0);
            }
        },
    },
};
