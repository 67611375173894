/** バリデーションタイプ */
class ValidationType {
    /** 必須チェック：入力 */
    static REQUIRED_INPUT = {
        message: "{0} は必須入力です。値を入力してください。",
    };
    /** 必須チェック：選択 */
    static REQUIRED_SELECT = {
        message: "{0} が未選択です。選択してください。",
    };
    /** 属性チェック：ファイル */
    static IS_FILE = {
        message: "{0} は{1}形式のファイルを選択してください。",
    };
    /** 属性チェック：数字（0以上の整数） */
    static IS_INTEGER = {
        message: "{0} は半角数字を入力してください。",
    };
    /** 属性チェック：年月 */
    static IS_YEAR_MONTH = {
        message: "{0} はyyyy年mm月の形式で入力してください。",
    };
    /** 属性チェック：日付 */
    static IS_DATE = {
        message: "{0} はyyyy/mm/ddの形式で入力してください。",
    };
    /** 基準有効期間チェック：範囲 */
    static IS_IN_RANGE = {
        message: "ライセンスコード： {0} の基準有効期間は {1}年 です。",
    };
    /** 属性チェック：英数字のみ（記号なし） */
    static NOT_CONTAIN_SYMBOLS = {
        message: "{0} は記号を含まない英数字で入力してください。",
    };
    // 未使用のためコメントアウト
    /** 範囲チェック */
    // static RANGE = {
    //   message: '{0} は{1}～{2}の間で入力してください。'
    // };
    /** 桁数チェック：最大 */
    static DIGITS_MAX = {
        message: "{0} は{1}文字以内で入力してください。",
    };
    /** 桁数チェック：最小 */
    static DIGITS_MIN = {
        message: "{0} は{1}文字以上で入力してください。",
    };
    /** 桁数チェック：固定長 */
    static FIXED_LENGTH = {
        message: "{0} は{1}文字で入力してください。",
    };
    /** メールアドレスチェック：正規表現 */
    static IS_MAIL = {
        message: "{0} はメールアドレス形式で入力してください。",
    };
    /** 郵便番号チェック：正規表現 */
    static IS_ZIP_CD = {
        message: "{0} は半角数字の郵便番号形式（ハイフンあり）で入力してください。",
    };
    /** 電話番号チェック：正規表現 */
    static IS_PHONE_NUM = {
        message: "{0} は半角数字の電話番号形式（ハイフンなし）で入力してください。",
    };
    /** パスワードチェック：正規表現 */
    static IS_PASSWORD = {
        message: "{0} は半角数字と半角英字をそれぞれ含む記号なしの形式で入力してください。",
    };
    /** ファイルサイズチェック：最大(MB) */
    static FILE_SIZE_MAX = {
        message: "{0} のファイルサイズは{1}MB以下にしてください。",
    };
}
export { ValidationType };
