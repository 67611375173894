import * as SecureLS from "secure-ls";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });
const initialState = {
    // ログインユーザの情報
    rToken: "",
    tokenExp: "",
    authority: "",
    storeCd: "",
    loginId: "",
    displayName: "",
    // モーダル状態管理
    isModalOpen: false,
    isModalInfo: false,
    isModalBtnReverse: false,
    cancelBtnHide: false,
    messages: [],
    okBtnMsg: "OK",
    cancelBtnMsg: "閉じる",
    okAction: function () {
        // do nothing.
    },
    cancelAction: function () {
        // do nothing.
    },
    // loading状態管理
    loading: false,
};
export default createStore({
    state: initialState,
    mutations: {
        setRToken(state, rToken) {
            state.rToken = rToken;
        },
        setTokenExp(state, tokenExp) {
            state.tokenExp = tokenExp;
        },
        setAuthority(state, authority) {
            state.authority = authority;
        },
        setStoreCd(state, storeCd) {
            state.storeCd = storeCd;
        },
        setLoginId(state, loginId) {
            state.loginId = loginId;
        },
        setDisplayName(state, displayName) {
            state.displayName = displayName;
        },
        setIsModalOpen(state, isModalOpen) {
            state.isModalOpen = isModalOpen;
        },
        setIsModalInfo(state, isModalInfo) {
            state.isModalInfo = isModalInfo;
        },
        setIsModalBtnReverse(state, isModalBtnReverse) {
            state.isModalBtnReverse = isModalBtnReverse;
        },
        setCancelBtnHide(state, cancelBtnHide) {
            state.cancelBtnHide = cancelBtnHide;
        },
        setMessages(state, messages) {
            state.messages = messages;
        },
        setOkBtnMsg(state, okBtnMsg) {
            state.okBtnMsg = okBtnMsg;
        },
        setCancelBtnMsg(state, cancelBtnMsg) {
            state.cancelBtnMsg = cancelBtnMsg;
        },
        setOkAction(state, okAction) {
            state.okAction = okAction;
        },
        setCancelAction(state, cancelAction) {
            state.cancelAction = cancelAction;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
    },
    plugins: [
        createPersistedState({
            // vuexに「vuex-persistedstate」を追加
            key: "ops-front-app",
            storage: {
                // secure-lsで暗号化
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
            // storage: window.localStrage, // ローカルで確認する用
        }),
    ],
});
